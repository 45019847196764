import React, { useEffect, useState } from "react";
import SelectOptions from "../SelctOptions";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { Icon } from "@iconify/react";
export default function Input({
  title,
  data,
  id,
  type,
  allFilters,
  setAllFilters,
  showFilteredValues,
  setSelected,
}) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const empty = {
    min: "",
    max: "",
  };

  const clearFilter = (type) => {
    switch (type) {
      case "min_max_engine":
        setAllFilters({ ...allFilters, size: empty });
        break;
      case "min_max_engine_power":
        setAllFilters({ ...allFilters, power: empty });
        break;
      case "cylinders":
        setAllFilters({ ...allFilters, cylinders: empty });
        break;
      case "doors":
        setAllFilters({ ...allFilters, doors: empty });
        break;
      case "seats":
        setAllFilters({ ...allFilters, seats: empty });
        break;
    }
  };

  const getContent = (type) => {
    switch (type) {
      case "min_max_engine":
        return (
          <div class="row">
            <div class="col-md-6 col-xl-4">
              <label className={`w-100 ${lang !== 'en' ? ' text-end ' : ''}`}>
                {t(data?.min?.name?.[i18n.language] || data?.min?.name?.[lang])}
              </label>
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data?.min?.data?.filter(
                    (v) => v?.value?.toString() === allFilters?.size?.min
                  )?.[0]?.name?.en || t("Any")
                }
                name={data?.min?.field}
                values={showFilteredValues?.(
                  "min_max_engine",
                  data?.min?.data,
                  allFilters.size.max,
                  "min"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    size: { ...allFilters?.size, min: e.target.value },
                  })
                }
              />
            </div>
            <div class="col-md-6 col-xl-4">
              <label className={`w-100 ${lang !== 'en' ? ' text-end ' : ''}`}>
                {t(data?.max?.name?.[i18n.language] || data?.max?.name?.[lang])}
              </label>
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data?.max?.data?.filter(
                    (v) => v?.value?.toString() === allFilters?.size?.max
                  )?.[0]?.name?.en || t("Any")
                }
                name={data?.max?.field}
                values={showFilteredValues?.(
                  "min_max_engine",
                  data?.max?.data,
                  allFilters.size.min,
                  "max"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    size: { ...allFilters.size, max: e.target.value },
                  })
                }
              />
            </div>
          </div>
        );
      case "min_max_engine_power":
        return (
          <div class="row">
            <div class="col-md-6 col-xl-4">
              <label className={`w-100 ${lang !== 'en' ? ' text-end ' : ''}`}>
                {t(data?.min?.name?.[i18n.language] || data?.min?.name?.[lang])}
              </label>
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data?.min?.data?.filter(
                    (v) => v?.value?.toString() === allFilters?.power?.min
                  )?.[0]?.name?.en || t("Any")
                }
                name={data?.min?.field}
                values={showFilteredValues?.(
                  "min_max_engine_power",
                  data?.min?.data,
                  allFilters.power.max,
                  "min"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    power: { ...allFilters.power, min: e.target.value },
                  })
                }
              />
            </div>
            <div class="col-md-6 col-xl-4">
              <label className={`w-100 ${lang !== 'en' ? ' text-end ' : ''}`}>
                {t(data?.max?.name?.[i18n.language] || data?.max?.name?.[lang])}
              </label>
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data?.max?.data?.filter(
                    (v) => v?.value?.toString() === allFilters?.power?.max
                  )?.[0]?.name?.en || t("Any")
                }
                name={data?.max?.field}
                values={showFilteredValues?.(
                  "min_max_engine_power",
                  data?.max?.data,
                  allFilters.power.min,
                  "max"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    power: { ...allFilters.power, max: e.target.value },
                  })
                }
              />
            </div>
          </div>
        );
      case "cylinders":
        return (
          <div class="row">
            <div class="col-md-6 col-xl-4">
              <label className={`w-100 ${lang !== 'en' ? ' text-end ' : ''}`}>
                {t(data?.min?.name?.[i18n.language] || data?.min?.name?.[lang])}
              </label>
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data?.min?.data?.filter(
                    (v) => v?.value == allFilters?.cylinders?.min
                  )?.[0]?.name?.en || t("Any")
                }
                name={data?.min?.field}
                values={showFilteredValues?.(
                  "cylinders",
                  data?.min?.data,
                  allFilters.cylinders.max,
                  "min"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    cylinders: { ...allFilters.cylinders, min: e.target.value },
                  })
                }
              />
            </div>
            <div class="col-md-6 col-xl-4">
              <label className={`w-100 ${lang !== 'en' ? ' text-end ' : ''}`}>
                {t(data?.max?.name?.[i18n.language] || data?.max?.name?.[lang])}
              </label>
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data?.max?.data?.filter(
                    (v) => v?.value == allFilters?.cylinders?.max
                  )?.[0]?.name?.en || t("Any")
                }
                name={data?.max?.field}
                values={showFilteredValues?.(
                  "cylinders",
                  data?.max?.data,
                  allFilters.cylinders.min,
                  "max"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    cylinders: { ...allFilters.cylinders, max: e.target.value },
                  })
                }
              />
            </div>
          </div>
        );
      case "doors":
        return (
          <div class="row">
            <div class="col-md-6 col-xl-4">
              <label className={`w-100 ${lang !== 'en' ? ' text-end ' : ''}`}>
                {t(data?.min?.name?.[i18n.language] || data?.min?.name?.[lang])}
              </label>
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data?.min?.data?.filter(
                    (v) => v?.value == allFilters?.doors?.min
                  )?.[0]?.name?.en || t("Any")
                }
                name={data?.min?.field}
                values={showFilteredValues?.(
                  "doors",
                  data?.min?.data,
                  allFilters.doors.max,
                  "min"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    doors: { ...allFilters.doors, min: e.target.value },
                  })
                }
              />
            </div>
            <div class="col-md-6 col-xl-4">
              <label className={`w-100 ${lang !== 'en' ? ' text-end ' : ''}`}>
                {t(data?.max?.name?.[i18n.language] || data?.max?.name?.[lang])}
              </label>
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data?.max?.data?.filter(
                    (v) => v?.value == allFilters?.doors?.max
                  )?.[0]?.name?.en || t("Any")
                }
                name={data?.max?.field}
                values={showFilteredValues?.(
                  "doors",
                  data?.max?.data,
                  allFilters.doors.min,
                  "max"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    doors: { ...allFilters.doors, max: e.target.value },
                  })
                }
              />
            </div>
          </div>
        );
      case "seats":
        return (
          <div class="row">
            <div class="col-md-6 col-xl-4">
              <label className={`w-100 ${lang !== 'en' ? ' text-end ' : ''}`}>
                {t(data?.min?.name?.[i18n.language] || data?.min?.name?.[lang])}
              </label>
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data?.min?.data?.filter(
                    (v) => v?.value == allFilters?.seats?.min
                  )?.[0]?.name?.en || t("Any")
                }
                name={data?.min?.field}
                values={showFilteredValues?.(
                  "seats",
                  data?.min?.data,
                  allFilters.seats.max,
                  "min"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    seats: { ...allFilters.seats, min: e.target.value },
                  })
                }
              />
            </div>
            <div class="col-md-6 col-xl-4">
              <label className={`w-100 ${lang !== 'en' ? ' text-end ' : ''}`}>
                {t(data?.max?.name?.[i18n.language] || data?.max?.name?.[lang])}
              </label>
              <SelectOptions
                style={"form-control"}
                selectedOption={
                  data?.max?.data?.filter(
                    (v) => v?.value == allFilters?.seats?.max
                  )?.[0]?.name?.en || t("Any")
                }
                name={data?.max?.field}
                values={showFilteredValues?.(
                  "seats",
                  data?.max?.data,
                  allFilters.seats.min,
                  "max"
                )}
                onChange={(e) =>
                  setAllFilters({
                    ...allFilters,
                    seats: { ...allFilters.seats, max: e.target.value },
                  })
                }
              />
            </div>
          </div>
        );
    }
  };

  return (
    <ul class="dropdown-menu drp" id={id}>
      <h5 class="mb-0">
        {`${t("Select")} ${title.toLowerCase()}`}
        <a
          onClick={() => {
            $(".drp").removeClass("show");
            $(".dropdown").removeClass("show");
            setSelected(null);
          }}
        >
          <img src="assets/images/close_img.png" alt="icon" />
        </a>
      </h5>
      <div class="setp">
        {getContent(type)}
        <p
          style={{ cursor: "pointer" }}
          className={`d-flex justify-content-start`}
          onClick={() => {
            if (clearFilter) {
              clearFilter(type);
            }
          }}
        >
          {/* <Icon icon="icon-park-solid:clear" /> Clear */}
          <button
            className="btn-hover-clear rounded-1 px-2 py-1"
            style={{
              backgroundColor: "white",
              border: "1px solid #4C7EE8",
              color: "#4C7EE8",
            }}
          >
            {t("Clear")}
          </button>
        </p>
      </div>
    </ul>
  );
}
