import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../Tools/Pagination";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { openAddressOnMap } from "../../../utils/constants";
import { Tooltip } from "react-tooltip";
import ViewImageModal from "../../Tools/ViewImageModal/view-image-modal";

export default function DealersList({
  responce = [],
  pageCount,
  childData,
  currentPage,
  setCurrentPage,
  ...props
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const itemsPerPage = 3;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // const filteredResponse = responce.filter((item) =>
  //   item.title[i18n.language].toLowerCase().includes(searchKeyword.toLowerCase())
  // );
  // const displayedResponse = filteredResponse.slice(startIndex, endIndex);

  const [ppModal, setPpModal] = useState(false);
  const [ppModalData, setPpModalData] = useState(null);
  const handleClosePpModal = () => {
    setPpModal(false);
  };
  const openPpModal = (data) => {
    setPpModalData(data);
    setPpModal(true);
  };

  useEffect(() => {
    setSearchKeyword(childData);
  }, [childData]);

  const dealers = props?.dealersData;

  if (responce?.length == 0) {
    return (
      <h3 style={{ height: "50vh" }} className="fs-4 fw-semibold">
        {t("No dealers found")}
      </h3>
    );
  }

  const gotoDealer = (id) => {
    navigate("/search_result_page" + "?id=" + id);
  };

  return (
    <div>
      {responce &&
        responce.map((item, key) => (
          <div className="car_set">
            <div className="row position-relative" key={key}>
              {item?.kyc?.name?.en.includes("verified") && (
                <span
                  data-tooltip-id="verified-tip"
                  data-tooltip-content={t("Verified by Ako Sayara")}
                  id="verified-tip"
                  className="position-absolute"
                  style={{
                    width: "fit-content",
                    top: "5px",
                    [lang === "en" ? "right" : "left"]: "5px",
                  }}
                >
                  <img
                    src="assets/images/verified.svg"
                    height={25}
                    width={25}
                  />
                </span>
              )}
              <div className="col-sm-4">
                <div className="img1">
                  <img
                    role="button"
                    onClick={() => openPpModal({ image: item?.coverPicture })}
                    src={
                      item?.coverPicture
                        ? item?.coverPicture
                        : "/assets/images/notFound.png"
                    }
                    style={{ objectFit: "contain", objectPosition: "center", border: '1px solid #ebebeb', borderRadius: '5px' }}
                    className="d-block w-100 h-100 "
                    alt="No profile picture"
                  />
                </div>
              </div>
              <div className="col-sm-8">
                <h5>{item?.dealerShipName}</h5>
                <p>{item?.address}</p>

                <a
                  className="view_car"
                  onClick={() => {
                    gotoDealer(item?._id);
                    // navigate("/search_result_page" + "?id=" + item?._id)
                  }}
                >
                  {t("View stock")}
                </a>

                <div className="cont">
                  <div className="row">
                    <div className="col-6">
                      <p>
                        <a href={`tel:${item?.phoneNumber}`}>
                          <img src="../assets/images/call.png" alt="icon" />
                          {t("Call")} {`${lang === 'en' ? "+" : ""}${item?.phoneNumber}${lang === 'en' ? "" : "+"}`}
                        </a>
                      </p>
                    </div>
                    <div className="col-6 text-end">
                      <p>
                        <a
                          onClick={() => {
                            try {
                              const res = openAddressOnMap(item?.address);
                              console.log(res);
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        >
                          <img src="../assets/images/location.png" alt="icon" />
                          {t("Get directions")}
                        </a>
                      </p>
                    </div>
                    <div className="col-6">
                      <p>
                        <a
                          role="button"
                          onClick={() => {
                            if (item?.additionalInfo?.website) {
                              window.open(item?.additionalInfo?.website);
                            } else {
                              gotoDealer(item?._id);
                            }
                          }}
                          target="_blank"
                        >
                          <img src="../assets/images/web.png" alt="icon" />
                          {t("Visit website")}
                        </a>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

      <nav aria-label="Page navigation example">
        <Pagination
          style={`pagination float-end mb-5 mt-4 ${
            lang !== "en" && "paginationButtonAr"
          }`}
          next={"Next"}
          previous={"Previous"}
          forcePage={currentPage}
          pageCount={pageCount}
          rangeDisplayed={3}
          handlePageClick={handlePageClick}
        />
      </nav>
      <ViewImageModal
        modal={ppModal}
        handleClose={handleClosePpModal}
        modalData={ppModalData}
      />
      <Tooltip
        id="verified-tip"
        variant="info"
        style={{ backgroundColor: "#29b2fe" }}
      />
    </div>
  );
}
