import React from "react";
import { NavLink, useParams } from "react-router-dom";
import HomeArticle from "../../../data/homeArticle.json";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { title } from "../../../data/content";

export default function SingleGuide({ data }) {
  const { t, i18n } = useTranslation();
  //  const params = useParams();
  //  const filter = HomeArticle.data.filter((item) => item.id === params.id);
  //  const arr=filter[0];
  //      var dt = new Date(arr.timeago*1000);
  //      var momentDate = moment(dt);
  //      var formattedDate = momentDate.format("Do MMM YYYY");
  //      document.title = title.Blog[i18n.language]+""+arr.title[i18n.language];
  return (
    <div>
      {/* {arr ? (
        <section class="blog_page">
          <div class="container">
            <h6 class="back">
              <NavLink to="/">
                <img src="../assets/images/backbt.png" /> {t("Go back")}
              </NavLink>
            </h6>
            <div class="set">
              <h6>
                {t("PUBLISHED ON ")} {formattedDate}
              </h6>
              <h2>{arr.title[i18n.language]}</h2>
              <p style={{ color: "#334660" }}>
                {arr.description[i18n.language]}{" "}
              </p>
              <div class="text-center">
                <img
                  class="img1"
                  src={`../assets/images/${arr.image}`}
                  alt="img"
                />
              </div>
            </div>
            {
              <div
                class="set"
                dangerouslySetInnerHTML={{ __html: arr.content }}
              />
            }
          </div>
        </section>
      ) : (
        ""
      )} */}
      <div className="container min-vh-100 mt-5 articleImages" >
        <p
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        ></p>
      </div>
    </div>
  );
}
