import React from "react";
import CompleteStep from "../../../Tools/CompletStep";
import { useTranslation } from "react-i18next";

export default function Final({ page, setReset,setStep }) {
  const { t } = useTranslation();
  console.log(page);
  return (
    <CompleteStep
      page={page}
      ptag={t(
        "Your Advert is placed and you can reveiw it under the manage advert page"
      )}
      h4tag={t("Your Ad is Posted")}
      page2={"MyAccount/Vehicles"}
      titlePage2={t("View my adverts")}
      titlePage1={t("Post another advert")}
      setReset={setReset}
      setStep={setStep}
    />
  );
}
