import React, { useEffect, useState } from "react";
import ListItem from "../ListItem";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { serachFilterSearch } from "../../../Reducer/api";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
export default function Select({
  type,
  title,
  data,
  id,
  handleChange,
  allFilters,
  setAllFilters,
  setSelected,
}) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const helperData = data;
  const [serchedlist, setSerachedList] = useState([]);
  const [Alllist, setAllList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (data) {
      setSerachedList(data?.slice(0, 5));
      setAllList(data);
    }
  }, [data]);

  const handleSearch = (value) => {
    if (value.length > 0) {
      const filteredData = helperData.filter((item) => {
        return item.label.toLowerCase().includes(value.toLowerCase());
      });
      setAllList(filteredData);
    } else {
      setAllList(helperData);
    }
  };

  const handleChangeInput = (event) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);
    handleSearch(inputValue);
  };

  const setTitle = (type) => {
    switch (type) {
      case "make":
        return t("Select Make");
      case "model":
        return t("Select Model");
    }
  };

  const setSubHeading = (type) => {
    switch (type) {
      case "make":
        return t("All makes");
      case "model":
        return t("All models");
    }
  };
  const handleSelectMakeModel = (e, item) => {
    switch (type) {
      case "make":
        setAllFilters({ ...allFilters, make: item?.name });
        break;
      case "model":
        setAllFilters({ ...allFilters, model: item?.value });
        break;
    }
    handleCloseModal();
  };

  const handleCloseModal = () => {
    $(".drp").removeClass("show");
    $(".dropdown").removeClass("show");
    setSelected(null);
  };

  const clearFilter = (type) => {
    switch (type) {
      case "make":
        setAllFilters({ ...allFilters, make: t("Any") });
        break;
      case "model":
        setAllFilters({ ...allFilters, model: t("Any") });
        break;
    }
    handleCloseModal();
  };
  return (
    <ul class="dropdown-menu drp" id={id} style={{ position: "relative" }}>
      <h5>
        {setTitle(type)}
        <a onClick={() => handleCloseModal()}>
          <img src="assets/images/close_img.png" alt="icon" />
        </a>
      </h5>
      {/* <form>
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          translate="text"
          placeholder={t("Search")}
          aria-label="Search"
          // value={searchValue}
          onChange={handleChangeInput}
        /></form> */}
      <h6 class="mt-0">{t("Most searched for")}</h6>
      <div class="setp">
        <ul>
          {serchedlist?.map((item, index) => {
            return (
              <li
                value={item}
                style={{ cursor: "pointer" }}
                onClick={(e) => handleSelectMakeModel(e, item)}
              >
                {t(item?.label || item?.value || item?.name)}
              </li>
            );
          })}
        </ul>
      </div>
      <h6>{setSubHeading(type)}</h6>

      <div class="setp d-flex flex-column">
        <ul>
          {Alllist?.map((item, index) => {
            return (
              <li
                value={item}
                style={{ cursor: "pointer" }}
                onClick={(e) => handleSelectMakeModel(e, item)}
              >
                {t(item?.label || item?.value || item?.name)}
              </li>
            );
          })}
        </ul>
        <p
          style={{
            cursor: "pointer",
          }}
          className={`d-flex justify-content-start`}
          onClick={() => {
            if (clearFilter) {
              clearFilter(type);
            }
          }}
        >
          {/* <Icon icon="icon-park-solid:clear" /> Clear */}
          <button
            className="btn-hover-clear rounded-1 px-2 py-1"
            style={{
              backgroundColor: "white",
              border: "1px solid #4C7EE8",
              color: "#4C7EE8",
            }}
          >
            {t("Clear")}
          </button>
        </p>
      </div>
    </ul>
  );
}
