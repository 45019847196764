import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import $ from 'jquery';
import {fetchPostByBrand } from '../../../Reducer/electricCarSlice';
import {ElectricDetail }from '../../../Reducer/electricCarSlice';
import SelectViewCar from '../../Tools/SelectViewCar';

export default function ElectricCarsType({rtl}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const searched = useSelector(state => state.electric_car.cardata);
  const auth = useSelector(state => state.auth.auth);
  let data={'id':auth && auth.id ? auth.id: ''};

  useEffect(() => {
    dispatch(ElectricDetail(data));
  }, [dispatch]);

  const selectBrand=(string,id)=>{
    $('.sl').removeClass('active');
    $(string+id).addClass('active');
    let form={brandId:id,id:auth.id ? auth.id: ''};
    dispatch(fetchPostByBrand(form));
  }

  let responce=searched.car_types && searched.car_types.length>0  ? searched.car_types : [];
  let responceList=searched.default_active && searched.default_active.length>0  ? searched.default_active : [];

  const handleClick=(datalist)=>{
    if(datalist)
    {
      dispatch(ElectricDetail(data));
    }
  }

  return (<SelectViewCar rtl={rtl} responce={responce} responceList={responceList} title={t('Find the right type of electric cars')} path={'hatchback_cars'} selectBrand={selectBrand} handleClick={handleClick}/>);
}


