import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useLocation, useNavigate } from "react-router";
import { myprofilefetch } from "../../../../../Reducer/myprofileSlice";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  staticWorkingHourData,
  removeAmPm,
} from "../../../../../utils/constants";
import { apiManager } from "../../../../../api/connectAPI";
import CustomTimePicker from "../../../../Tools/CustomTimePicker/CustomTimePicker";
import moment from "moment";

export default function EditWorkingHour() {
  const { t, i18n } = useTranslation();
  const data = useSelector((state) => state.myprofile.profilearr);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(myprofilefetch());
  }, [dispatch]);
  //   const hour = data.edithours;
  const lang1 = useSelector((state) => state.auth.language);
  const lang2 = lang1 === "English" ? "en" : lang1 === "Arabic" ? "ar" : "ku";
  const [hour, setHour] = useState([]);
  const additionalInfo = location?.state?.data;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setHour(additionalInfo?.dealerWorkingHours || staticWorkingHourData);
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    console.log(hour);
    apiManager("PUT", `user/profile?lang=${lang2}`, {
      additionalInfo: { ...additionalInfo, dealerWorkingHours: hour },
    })
      .then((res) => {
        console.log(res);
        if (res.status) {
          toast.success(t("Working hours updated"));
          navigate("/MyAccount/MyProfile1");
        } else {
          toast.error("Failed to update");
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
        console.error(err.message);
        setLoading(false);
      });
  };

  const setClosed = (e, item, i) => {
    let temp = [...hour];
    temp[i] = { ...item, active: !e.target.checked };
    setHour(temp);
  };

  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const handleCloseModal = () => {
    setModal(false);
  };
  const openModal = (data) => {
    setModalData(data);
    setModal(true);
  };

  return (
    <div className="my_profile">
      <div>
        <h3>
          {t("Working hours")}{" "}
          <span class="d-none d-sm-block">
            <NavLink to="/MyAccount/MyProfile1">{t("Cancel")}</NavLink>
            <button
              className="save"
              type="submit"
              onClick={handleSubmit}
              disabled={loading}
            >
              {t("Save")}
            </button>
          </span>
        </h3>

        <div class="row working_hrs mt-4">
          {hour &&
            hour?.map?.((item, index) => {
              return (
                <div
                  class="col-sm-12 col-xs-12 border rounded-2 px-3 pt-3 m-1 w-100"
                  key={item.index}
                >
                  <input
                    type="hidden"
                    name="day[]"
                    value={item.day ? item.day[lang2] : ""}
                  />
                  <h6 style={{ paddingTop: "0px" }}>
                    {item?.day ? t(item.day) : ""}
                    <span
                      style={{
                        cursor: "pointer",
                        [`margin${lang2 === "en" ? "Right" : "Left"}`]: "80px",
                        top: "10px",
                      }}
                      className="d-flex justify-content-start align-items-center closed"
                    >
                      {/* <input
                        type="checkbox"
                        id={`time${index}`}
                        checked={!item?.active}
                        className={lang2 === 'en' ? "me-1" : 'ms-1'}
                        onChange={(e) => setClosed(e, item, index)}
                      /> */}
                      <div
                        class="form-check form-switch"
                        style={{ paddingInline: "unset" }}
                      >
                        <input
                          style={{ border: "none !important" }}
                          class={`form-check-input ${
                            !item?.active ? "bg-danger" : ""
                          } ${lang2 === "en" ? "me-1" : "ms-1"}`}
                          type="checkbox"
                          role="switch"
                          id={`time${index}`}
                          checked={!item?.active}
                          onChange={(e) => setClosed(e, item, index)}
                        />
                        <label
                          class={`form-check-label ${
                            lang2 === "en" ? "" : "px-2"
                          }`}
                          style={{ marginTop: "2px" }}
                          htmlFor={`time${index}`}
                        >
                          {t("Closed")}
                        </label>
                      </div>
                      {/* <label htmlFor={`time${index}`}>{t("Closed")}</label> */}
                    </span>
                    <span>
                      {/* <select
                        disabled={!item?.active}
                        class="form-control px-1"
                        defaultValue={(item?.startTime).toUpperCase()}
                        onChange={(e) => setStartTime(e, item?.index)}
                        name="from[]"
                      >
                        {timeData12.map((item, key) => {
                          return <option key={key} value={item}>{removeAmPm(item)}</option>;
                        })}
                      </select>
                      {t("to")}
                      <select
                        disabled={!item?.active}
                        class="form-control px-1"
                        defaultValue={(item?.endTime).toUpperCase()}
                        onChange={(e) => setEndTime(e, item?.index)}
                        name="to[]"
                      >
                        {timeData12.map((item, key) => {
                          return <option key={key} value={item}>{removeAmPm(item)}</option>;
                        })}
                      </select> */}
                      <div className="d-flex justify-content-around align-items-center">
                        <div
                          role={item?.active ? "button" : ""}
                          className={`form-control h-50 ${
                            !item?.active ? "bg-light" : ""
                          }`}
                          style={{ width: "fit-content" }}
                          onClick={(e) => {
                            if (item?.active) {
                              openModal({
                                time: item?.startTime,
                                type: "startTime",
                                index: item?.index,
                                day: item?.day,
                              });
                            }
                          }}
                        >
                          {removeAmPm(item?.startTime)}
                        </div>
                        <div>{t("to")}</div>
                        <div
                          role={item?.active ? "button" : ""}
                          className={`form-control h-50 ${
                            !item?.active ? "bg-light" : ""
                          }`}
                          style={{ width: "fit-content" }}
                          onClick={(e) => {
                            if (item?.active) {
                              openModal({
                                time: item?.endTime,
                                type: "endTime",
                                index: item?.index,
                                day: item?.day,
                              });
                            }
                          }}
                        >
                          {removeAmPm(item?.endTime)}
                        </div>
                      </div>
                    </span>
                  </h6>
                </div>
              );
            })}

          <CustomTimePicker
            modal={modal}
            handleCloseModal={handleCloseModal}
            modalData={modalData}
            onChange={(e) => {
              let temp = hour;
              temp[modalData?.index][modalData?.type] = moment(e.$d).format(
                "hh:mm a"
              );
              setHour(temp);
            }}
          />

          <div class="col-12 d-block d-sm-none albtn">
            <a class="save w-100" onClick={handleSubmit}>
              {t("Save")}
            </a>
            <NavLink class="w-100 mb-3" to="/MyAccount/MyProfile1">
              {t("Cancel")}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
