import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Tools/Button";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../../Tools/FormInput";
import PhoneNumberInput from "../../Tools/PhoneNumberInput";
import SelectForm from "../../Tools/SelectForm";
import TextAreaForm from "../../Tools/TextAreaForm";
import { clearStatus, detailForm } from "../../../Reducer/detailSlice";
import { cities, errorSms, getAllGenders } from "../../../data/content";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import GoogleMapAddress from "../../Tools/MapInput";

//Date Picker Imports
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { utils } from "react-modern-calendar-datepicker";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import { apiManager } from "../../../api/connectAPI";
import CustomPhoneInput from "../../Tools/CustomPhoneInput";
import CustomDatepicker from "../../Tools/Datepicker";
import { twoDig } from "../../../utils/constants";
import moment from "moment";
import { sendWelcomeMail } from "../../../Reducer/api";
// import { registerLocale, setDefaultLocale } from  "react-datepicker";
// import en from 'date-fns/locale/en-US';
// import ar from 'date-fns/locale/ar';
// import ku from 'date-fns/locale/ku';

export default function Personal({ sx, proceed }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitting = useSelector((state) => state.detail.submitting);
  const detail = useSelector((state) => state.detail.auth);
  const error = useSelector((state) => state.detail.error);
  const success = useSelector((state) => state.detail.success);
  const { t } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const [genders, setGenders] = useState([]);

  // registerLocale('en', en)
  // registerLocale('ar', ar)
  // registerLocale('ku', ku)

  if (success) {
    clearStatus();
    proceed("user");
    // navigate("/");
  }
  if (error) {
    clearStatus();
    console.log(error);
  }
  console.log("success", success);
  console.log("error", error);

  useEffect(() => {
    getAllGenders()
      .then((res) => {
        setGenders(res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const [loginType, setLoginType] = useState(null);
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    const Ltype = localStorage.getItem("type");
    const Utype = localStorage.getItem("userType");

    setLoginType(Ltype);
    setUserType(Utype);
  }, [loginType, userType]);

  // let move = success && !submitting;
  // console.log(success)
  // if (move && userType === 'user') {
  //   navigate("/");
  // }

  const schema = Yup.object().shape({
    email:
      loginType !== "email"
        ? Yup.string().email(t("Please Enter Valid email address"))
        : Yup.string().email(t("Please Enter Valid email address")),
    phoneNumber:
      loginType == "email"
        ? Yup.string()
            .min(9, t("Phone number is not valid"))
            .max(16, t("Phone number is not valid"))
        : Yup.string(),
    fullName: Yup.string()
      .min(3, t("Full name must be at least 3 characters"))
      .max(30, t("Full name must be at most 30 characters")),
    gender: Yup.string()
      .required()
      .test("notChoose", t("Gender is required"), (v) => v !== t("Select")),
    dob: Yup.mixed()
      .required(t("This field is requried"))
      .label(t("Date of birth")),
    city: Yup.string()
      .required(t("City is required"))
      .test("notChoose", t("City is required"), (v) => v !== t("Select")),
    address: Yup.string()
      .required(t("Address is a required field"))
      .min(6, t("Address must be at least 6 characters")),
  });

  const {
    control,
    register,
    setError,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      phoneNumber: "",
      dob: {
        day: 1,
        month: 4,
        year: 2008,
      },
    },
  });

  const onError = (errors) => {
    console.log(errors);
  };

  const onSubmitform = async (data) => {
    // if (loginType === "email" && phoneNumberErrors.length !== 0) {
    //   setPhoneNumberErrors(t("Phone number is a required field"));
    //   return;
    // }

    if (submitting == false) {
      const finalData = data;
      if (loginType === "phone") delete finalData.phoneNumber;
      if (loginType === "email") delete finalData.email;

      finalData.dateOfBirth = moment(
        twoDig(data.dob.month) +
          "/" +
          twoDig(data.dob.day) +
          "/" +
          data.dob.year
      ).valueOf();

      finalData.city = cities.filter(
        (v) => t(v?.name?.[lang]) === data.city
      )?.[0]?.name?.["en"];
      const genderId = genders.filter((v) => v.name[lang] === data.gender);
      finalData["genderId"] = genderId[0]._id;

      finalData["userType"] = "user";
      finalData["language"] = language;
      finalData["isProfileCompleted"] = true; 
      
      console.log(finalData);

      const attribute = loginType === "email" ? "phoneNumber" : "email";
      const value = loginType === "email" ? data?.phoneNumber : data.email;
      if (value.length > 0) {
        apiManager(
          "GET",
          `general/checkAvailability?lang=en&attribute=${attribute}&value=${value}`
        )
          .then((res) => {
            if (res.status) {
              dispatch(detailForm(finalData));
            } else {
              // setPhoneNumberErrors(res.message);
              setError("phoneNumber", { message: res.message });
              setError("email", { message: res.message });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        dispatch(detailForm(finalData));
      }
    }
  };

  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [phoneNumberErrors, setPhoneNumberErrors] = useState("");

  // const [modal, setModal] = useState(false)
  // const handleCloseModal = () => {
  //   setModal(false)
  // }
  return (
    <div
      class={`tab-pane fade ${sx}`}
      id="Personal"
      role="tabpanel"
      aria-labelledby="Personal-tab"
    >
      {/* {success && detail && (
        <div>
          {t("Success")}: {detail.message}
        </div>
      )} */}
      <form onSubmit={handleSubmit(onSubmitform, onError)}>
        <div class="form-group">
          <label>{t("Full Name")}*</label>
          <FormInput
            style={"form-control"}
            intype={"text"}
            event={register}
            name={"fullName"}
            placeholder={t("Please enter full name")}
          />
          {errors.fullName && <p>{t(errors.fullName.message)}</p>}
        </div>
        {loginType === "phone" ? (
          <div class="form-group">
            <label>{t("Email")}</label>
            <FormInput
              style={"form-control"}
              intype={"email"}
              event={register}
              name={"email"}
              placeholder={t("Please Enter the Email Address")}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
        ) : (
          <div class="form-group my-3">
            <label>{t("Phone")}*</label>
            {/* <FormInput
              style={"form-control"}
              intype={"phone"}
              event={register}
              name={"phone"}
              placeholder={t("Please Enter the Phone Number")}
            /> */}
            {/* <PhoneNumberInput
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              phoneNumberErrors={phoneNumberErrors}
              setPhoneNumberErrors={setPhoneNumberErrors}
            /> */}
            <CustomPhoneInput
              event={register}
              name={"phoneNumber"}
              setValue={setValue}
              getValues={getValues}
            />
            {errors.phoneNumber ? <p>{t(errors.phoneNumber.message)}</p> : ""}
          </div>
        )}
        <div class="form-group">
          <label>{t("Gender")}*</label>
          <SelectForm
            style={"form-control"}
            event={register}
            name={"gender"}
            selectedOption={""}
            values={genders}
          />
          {errors.gender && <p>{t(errors.gender.message)}</p>}
        </div>
        <div class="form-group d-flex flex-column">
          <label>{t("Date of birth")}*</label>
          <Controller
            control={control}
            name="dob"
            render={({ field }) => (
              // <ReactDatePicker
              //   // locale={lang}
              //   dateFormat='PP'
              //   className="form-control"
              //   wrapperClassName="d-block"
              //   placeholderText={t('Select')}
              //   onChange={(date) => field.onChange(date)}
              //   selected={field.value}
              // />
              // <DatePicker
              //   value={field.value}
              //   onChange={field.onChange}
              //   inputPlaceholder={t("Select")}
              //   colorPrimary="#334660"
              //   maximumDate={utils().getToday()}
              //   inputClassName={`form-control text-${
              //     lang === "en" ? "start" : "end"
              //   }`}
              //   shouldHighlightWeekends
              // />
              <CustomDatepicker
                onChange={(date) => field.onChange(date)}
                value={field.value}
                placeholder={t("Select")}
                maxDate={utils().getToday()}
                inputClassName={`form-control text-${
                  lang === "en" ? "start" : "end"
                }`}
              />
            )}
          />
          {errors.dob && (
            <p className="helperText" style={{ bottom: "-1px" }}>
              {t(errors.dob.message)}
            </p>
          )}
        </div>
        <div class="form-group">
          <label>{t("City")}*</label>
          <SelectForm
            style={"form-control"}
            event={register}
            name={"city"}
            selectedOption={""}
            values={cities}
          />
          {errors.city && <p>{t(errors.city.message)}</p>}
        </div>
        <div class="form-group">
          <label>{t("Address")}*</label>
          <TextAreaForm
            event={register}
            name={"address"}
            style="form-control"
          />
          {errors.address && <p>{t(errors.address.message)}</p>}
        </div>
        <Button
          style="btn mt-3"
          title={submitting == false ? t("Continue") : t("Please wait...")}
          type="submit"
        />
      </form>
      {/* <Modal isOpen={modal} centered toggle={handleCloseModal}>
        <ModalHeader >Point the map to your location</ModalHeader>
        <ModalBody>
          <GoogleMapAddress handleCloseModal={handleCloseModal} setValue={setValue} />
        </ModalBody>
      </Modal> */}
    </div>
  );
}
