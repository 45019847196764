import React from "react";
import { homeOptions } from "../../../data/content";
import OwlCarousel from "react-owl-carousel-rtl";
import { NavLink, useNavigate } from "react-router-dom";
import OwlItem from "../OwlItem";
import { useTranslation } from "react-i18next";
import OwlItemCars from "../OwlItem/owlItemCars";

export default function OwlCarouselSection({
  responce,
  title1,
  title2,
  rtl,
  ...props
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const goToViewAllCars = (id) => {
    navigate(`/DealerCarView?ownerId=${id}`);
  };
  console.log(responce)
  return (
    <section class="recently_viewed">
      {responce && responce.length != 0 ? (
        <div class="container">
          <h2 class="hed">{t(title1)}</h2>
          <OwlCarousel
            className={`owl-carousel Recently_carousel owl.carousel.min owl-theme ${rtl && 'invertButtons'}`}
            {...homeOptions}
            // margin={10}
            rtl={rtl}
            nav
          >
            {/* <OwlItem key={0} singleDealer={props?.singleDealer} fill={'heart_fill.png'} unfill={'heart.webp'} responce={responce} page={'BrandNewSection'}/> */}
            <OwlItemCars
              key={0}
              cars={responce}
              page={"BrandNewSection"}
              rentType={'daily'}
            />
          </OwlCarousel>
          <div class="w-100 float-left text-center">
            <a
              className="viewall"
              style={{ cursor: "pointer" }}
              onClick={() => goToViewAllCars(responce?.[0]?.owner?._id)}
            >
              {t(title2)}
            </a>
          </div>
        </div>
      ) : null}
    </section>
  );
}
