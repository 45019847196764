export const sideBarList = [
  {
    name: "My vehicles",
    segment: "Vehicles",
  },
  {
    name: "Messages",
    segment: "Messages",
  },
  {
    name: "My profile",
    segment: "MyProfile",
  },
  {
    name: "Transactions",
    segment: "Transactions",
  },
  {
    name: "Perfomance",
    segment: "Perfomance",
  },
  {
    name: "Contact Us",
    segment: "ContactUs",
  },
  {
    name: "Terms & Conditions",
    segment: "Terms-Conditions",
  },
  {
    name: "Why Ako Sayara",
    segment: "WhyAkoSayara",
  },
  {
    name: "Privacy policies",
    segment: "PrivacyPolicies",
  },
  {
    name: "Cookies",
    segment: "Cookies",
  },
  {
    name: "Logout",
    action: "logout",
    segment: "/",
  },
];

// data table column name
export const tableColumn = [
  {
    id: "1",
    name: "DATE",
  },
  {
    id: "2",
    name: "INVOICE NUMBER",
  },
  {
    id: "3",
    name: "STATUS",
  },
  // {
  //   id: "4",
  //   name: "ACTIONS",
  // },
  {
    id: "5",
    name: "AMOUNT",
  },
];

export const Terms = {
  _id: "1",
  title: {
    English: "Terms and Conditions",
    Arabic: "الأحكام والشروط",
    Kurdish: "بار و دۆخ و مەرجەکان",
  },

  description:
    "<p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p><p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p><p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p><p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>",
};
export const why = {
  _id: "1",
  title: {
    English: "Why Ako Sayara",
    Arabic: "لماذا اكو سيارة",
    Kurdish: "بۆچی ئەکۆ سەیارة",
  },
  description:
    " <div class='img'><img class='w-100' src='../assets/images/why_img.jpg' alt='icon'/></div><p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p><p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p><div class='img_video'><img class='w-100' src='../assets/images/why_video.jpg' alt='icon'/></div><p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>",
};
export const Privacy = {
  _id: "1",
  title: {
    English: "Privacy policies",
    Arabic: "سياسات الخصوصية",
    Kurdish: "سیاسەتەکانی پاراستنی نهێنی",
  },
  description:
    " <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p><p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p><p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p><p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>",

  who_are_we: {
    English:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    Arabic:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    Kurdish:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
  },

  howweuse: {
    English:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    Arabic:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    Kurdish:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
  },

  account_creation: {
    English:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    Arabic:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    Kurdish:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
  },

  placing_advertisment: {
    English:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    Arabic:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
    Kurdish:
      "Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.",
  },
};

export const describes = {
  contactContant: [
    {
      id: "1",
      title: {
        English: "I’m a private seller",
        Arabic: "أنا بائع خاص",
        Kurdish: "من فرۆشیارێکی تایبەتم",
      },
      description: {
        English:
          "You own a vehicle that you’re selling, or plan to sell, on Ako Sayara",
        Arabic: "أنت تمتلك سيارة للبيع او تخطط لبيعها على اكو سيارة",
        Kurdish:
          "تۆ خاوەنی ئۆتۆمبێلێکیت بۆ فرۆشتن یان پلانت هەیە لە کۆمپانیای ئەکۆ سەیارە بیفرۆشیت",
      },
      image: "../assets/images/best1.png",
    },
    {
      id: "2",
      title: {
        English: "I’m a dealer",
        Arabic: "أنا تاجر",
        Kurdish: "من بازرگانم",
      },
      description: {
        English: "You sell vehicles as part of your job or business",
        Arabic: "أنت تبيع السيارات كجزء من شغلك او عملك",
        Kurdish: "تۆ ئۆتۆمبێل دەفرۆشیت وەک بەشێک لە کارەکەت",
      },
      image: "../assets/images/best2.png",
    },
    {
      id: "3",
      title: {
        English: "I’m looking to buy",
        Arabic: "أنا أتطلع للشراء",
        Kurdish: "من بەدوای کڕیندا دەگەڕێم",
      },
      description: {
        English: "You’re in the market for a new or used vehicle",
        Arabic: "أنت في السوق لشراء سيارة جديدة أو مستعملة.",
        Kurdish: "تۆ لە بازاڕدایت بۆ ئۆتۆمبێلێکی نوێ یان بەکارهاتوو.",
      },
      image: "../assets/images/mag_glass.png",
    },
    // {
    //   id: "4",
    //   title: {
    //     English: "Review enquiries",
    //     Arabic: "مراجعة الاستفسارات",
    //     Kurdish: "پێداچوونەوە بە پرسیارەکاندا بکە",
    //   },
    //   description: {
    //     English: "You have a problem with a review",
    //     Arabic: "لديك مشكلة مع تعليق",
    //     Kurdish: "کێشەیەکت هەیە لە کۆمێنتێک",
    //   },
    //   image: "../assets/images/best4.png",
    // },
    {
      id: "4",
      title: {
        English: "I want to complain",
        Arabic: "أريد أن أشتكي",
        Kurdish: "دەمەوێت گلەیی بکەم",
      },
      description: {
        English: "You have a problem we have not been able to solve",
        Arabic: "لديك مشكلة لم نتمكن من حلها",
        Kurdish: "کێشەیەکت هەیە کە ئێمە نەمانتوانی چارەسەری بکەین",
      },
      image: "../assets/images/best5.png",
    },
    {
      id: "5",
      title: {
        English: "Different enquiry",
        Arabic: "استفسار مختلف",
        Kurdish: "لێکۆڵینەوەی جیاواز",
      },
      description: {
        English: "You don’t fit any of those categories",
        Arabic: "أنت لا تناسب أيًا من هذه الفئات",
        Kurdish: "تۆ لەگەڵ هیچ کام لەم پۆلانەدا ناگونجێت",
      },
      image: "../assets/images/best6.png",
    },
  ],

  topic: {
    English: "Help topic head goes here",
    Arabic: "Help topic head goes here",
    Kurdish: "Help topic head goes here",
  },
  topic_description: {
    English:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
    Arabic:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
    Kurdish:
      "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
  },
  closeoption: [
    {
      id: "1",
      title: {
        English: "Select reason for closing your account",
        Arabic: "Select reason for closing your account",
        Kurdish: "Select reason for closing your account",
      },
      name: {
        English: "Select a reason",
        Arabic: "أختر السبب",
        Kurdish: "هۆکارەکە هەڵبژێرە",
      },
    },
    {
      id: "2",
      title: {
        English: "Select reason for closing your account",
        Arabic: "Select reason for closing your account",
        Kurdish: "Select reason for closing your account",
      },
      name: {
        English: "Select a reason",
        Arabic: "أختر السبب",
        Kurdish: "هۆکارەکە هەڵبژێرە",
      },
    },
    {
      id: "3",
      title: {
        English: "Select reason for closing your account",
        Arabic: "Select reason for closing your account",
        Kurdish: "Select reason for closing your account",
      },
      name: {
        English: "Select a reason",
        Arabic: "أختر السبب",
        Kurdish: "هۆکارەکە هەڵبژێرە",
      },
    },
  ],
  tell_title: {
    English: "Tell us",
    Arabic: "أخبرنا",
    Kurdish: "پێمان بڵێ",
  },
};

export const myvehicle = {
  data: [
    {
      id: "1",
      name: {
        English: "Ford Ecosport",
        Arabic: "Ford Ecosport",
        Kurdish: "Ford Ecosport",
      },
      details: {
        English: "2011 | SUV | 37,245 miles | 1.6L | 112BHP Manual | Diesel",
        Arabic: "2011 | SUV | 37,245 miles | 1.6L | 112BHP Manual | Diesel",
        Kurdish: "2011 | SUV | 37,245 miles | 1.6L | 112BHP Manual | Diesel",
      },
      amount: "15,000",
      symbol: "$",
      image: "../assets/images/recently_viewed1.jpg",
      trash_image: "../assets/images/trash.png",
      buyer: [
        {
          buyer_name: "Jonathan",
        },
        {
          buyer_name: "Jonathan",
        },
        {
          buyer_name: "Jonathan",
        },
      ],
    },
    {
      id: "2",
      name: {
        English: "Ford Ecosport",
        Arabic: "Ford Ecosport",
        Kurdish: "Ford Ecosport",
      },
      details: {
        English: "2011 | SUV | 37,245 miles | 1.6L | 112BHP Manual | Diesel",
        Arabic: "2011 | SUV | 37,245 miles | 1.6L | 112BHP Manual | Diesel",
        Kurdish: "2011 | SUV | 37,245 miles | 1.6L | 112BHP Manual | Diesel",
      },
      amount: "15,000",
      symbol: "$",
      image: "../assets/images/recently_viewed2.jpg",
      trash_image: "../assets/images/trash.png",
      buyer: [
        {
          buyer_name: "Jonathan",
        },
        {
          buyer_name: "Jonathan",
        },
        {
          buyer_name: "Jonathan",
        },
      ],
    },
    {
      id: "3",
      name: {
        English: "Ford Ecosport",
        Arabic: "Ford Ecosport",
        Kurdish: "Ford Ecosport",
      },
      details: {
        English: "2011 | SUV | 37,245 miles | 1.6L | 112BHP Manual | Diesel",
        Arabic: "2011 | SUV | 37,245 miles | 1.6L | 112BHP Manual | Diesel",
        Kurdish: "2011 | SUV | 37,245 miles | 1.6L | 112BHP Manual | Diesel",
      },
      amount: "15,000",
      symbol: "$",
      image: "../assets/images/recently_viewed3.jpg",
      trash_image: "../assets/images/trash.png",
      buyer: [
        {
          buyer_name: "Jonathan",
        },
        {
          buyer_name: "Jonathan",
        },
        {
          buyer_name: "Jonathan",
        },
      ],
    },
  ],
};
