import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Tools/Button";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorSms, phoneRegExp } from "../../../data/content";
import { loginForm } from "../../../Reducer/authSlice";
import FormInput from "../../Tools/FormInput";
import PhoneNumberInput from "../../Tools/PhoneNumberInput";
import { toast } from "react-toastify";
import { convertDigitsToEnglish } from "../../../utils/constants";
import CustomPhoneInput from "../../Tools/CustomPhoneInput";
export default function LoginForm({
  type,
  backPage,
  backPageData,
  requestRedirect,
  adIdToChat,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitting = useSelector((state) => state.auth.submitting);
  const login = useSelector((state) => state.auth.auth);
  const error = useSelector((state) => state.auth.error);
  const success = useSelector((state) => state.auth.success);
  const { t, i18n } = useTranslation();
  let move = success && login?.form && login?.auth != {} ? login?.form : "";
  //   switch (move) {
  //     case "pending":
  //       navigate("/Details");
  //       break;
  //     case "clear":
  //       navigate("/");
  //       break;
  //   }

  if (move) {
    if (requestRedirect === "chat") {
      localStorage.setItem("chatInitiated", "true");
      localStorage.setItem("adIdToChat", adIdToChat);
    }
    navigate(backPage, { state: backPageData });
  }

  const schema = Yup.object().shape({
    email:
      type == "email"
        ? Yup.string()
            .required(t("Email is a required field"))
            .email(t("Please Enter Valid email address"))
        : Yup.string(),
    phoneNumber:
      type == "phone"
        ? Yup.string()
            .min(9, t("Phone number is not valid"))
            .max(16, t("Phone number is not valid"))
        : Yup.string(),
    password: Yup.string()
      .min(6, t("Password must be at least 6 characters"))
      .max(30, t("Password must be at most 30 characters"))
      .required(t("Password is a required field")),
    type: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      phoneNumber: "",
    },
  });

  const onError = (errors) => {
    console.log(errors);
  };

  const onSubmit = async (data) => {
    if (type === "phone") {
      delete data["email"];
    }
    if (type === "email") {
      delete data?.phoneNumber;
    }
    console.log(data);
    if (submitting == false) {
      //   let formData = new FormData();
      //   formData.append("phone", data.phone);
      //   formData.append("email", data.email);
      //   formData.append("password", data.password);
      //   formData.append("type", type);
      //   formData.append("language", i18n.language);
      dispatch(loginForm(data));
    }
  };
  useEffect(() => {
    if (error) {
      if (type === "phone") {
        // setPhoneNumberErrors(t(error?.payload));
        setError("phoneNumber", { message: error.payload });
      } else {
        console.log(typeof error.payload);
        setError("email", { message: error.payload });
      }
    }
  }, [error]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div
          class="tab-pane fade show active"
          id="With_Phone"
          role="tabpanel"
          aria-labelledby="With_Phone-tab"
        >
          {/* <div class="form-group">
            <label>{type == "email" ? t("Email") : t("Phone")}</label>
            <FormInput
              style={"form-control no-zoom"}
              intype={type == "phone" ? "number" : "email"}
              event={register}
              name={type}
              placeholder={
                type == "email"
                  ? t("Please Enter the Email Address")
                  : t("Please Enter the Phone Number")
              }
            />
            {errors.email ? (
              <p>{t("Email is a required field")}</p>
            ) : (
              errors.phone && <p>{t("Phone number is not valid")}</p>
            )}
          </div> */}
          {type === "email" ? (
            <div class="form-group">
              <label>{t("Email")}*</label>
              <FormInput
                style={"form-control"}
                intype="email"
                event={register}
                name="email"
                placeholder={t("Please Enter the Email Address")}
              />
              {errors.email ? <p>{t(errors.email.message)}</p> : ""}
            </div>
          ) : (
            <div className="mb-2">
              <label>{t("Phone")}*</label>
              {/* <PhoneNumberInput
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                phoneNumberErrors={phoneNumberErrors}
                setPhoneNumberErrors={setPhoneNumberErrors}
              /> */}
              <div className="form-group">
                <CustomPhoneInput
                  event={register}
                  name={"phoneNumber"}
                  setValue={setValue}
                  getValues={getValues}
                />
                {errors.phoneNumber ? (
                  <p>{t(errors.phoneNumber.message)}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          <div class="form-group">
            <label>{t("Password")}*</label>
            <span class="password d-block">
              <FormInput
                style={"form-control no-zoom"}
                intype={"password"}
                event={register}
                name={"password"}
                placeholder={t("Please Enter The Password")}
              />
              <i class="far fa-eye"></i>
            </span>

            <div style={{ textAlign: "right" }}>
              <a
                onClick={() =>
                  navigate("/ForgotPassword", { state: { type: type } })
                }
              >
                {t("Forgot Password?")}
              </a>
            </div>

            {errors.password && <p>{errors.password.message}</p>}
          </div>

          <br />

          <Button
            style="btn"
            title={submitting == false ? t("Login") : t("Please wait...")}
            type="submit"
          />
        </div>
      </form>
    </>
  );
}
