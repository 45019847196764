import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
const Meta = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <meta name="description" content={t('metaDescription')} />
    </Helmet>
  );
};
export default Meta;