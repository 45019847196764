import React from "react";
import { useTranslation } from "react-i18next";
import ArticlesSection from "../../Tools/ArticleBlog/ArticlesSection";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getArticles, getSiteContent } from "../../../Reducer/api";
import { marked } from "marked";
import SingleGuide from "./SingleGuide";
export default function Guide() {
  const { t, i18n } = useTranslation();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const params = useParams()
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const [data, setData] = useState(null);

  useEffect(() => {
    getArticles(params.id).then((res) => {
      res.json().then((r) => {
        console.log(r);
        const temp = r?.data?.attributes
        setData({
          en: temp.content_en,
          ar: temp.content_ar,
          ku: temp.content_ku
        });
      });
    });
  }, [params.id]);


  marked.setOptions({
    gfm: true,
    highlight: false,
    tables: false,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
    langPrefix: false,
  });

  console.log(data)

  return (
    <div>
      <SingleGuide data={marked.parse(data ? (data?.[lang]) : t("Please wait..."))} />
      {/* <ArticlesSection rtl={rtl} /> */}
    </div>
  );
}
