import React, { useEffect, useState } from "react";
import Describes from "./Describes";
import Inquireform from "./Inquireform";
import { useTranslation } from "react-i18next";
import { title } from "../../../data/content";

export default function Contactus() {
  const { t, i18n } = useTranslation();
  document.title = title.Contactus[i18n.language];
  const [formData, setFormData] = useState({});
  useEffect(() => {
    console.log(formData);
  }, [formData]);
  return (
    <div className="support_enquiry">
      <Describes formData={formData} setFormData={setFormData} />
      <Inquireform formData={formData} setFormData={setFormData} />
    </div>
  );
}
