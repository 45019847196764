import React, { useEffect, useState } from "react";
import SelectOptions from "../../Tools/SelctOptions";
import Button from "../../Tools/Button";
import { homebanner } from "../../../data/content";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import filter from "../../../data/filter.json";
import { showFilteredValues } from "../../../utils/constants";
import SelectCustomItems from "../../Tools/SelectCustomItems";

export default function SecondForm({
  data,
  carTypes,
  models,
  filterData,
  setFilterData,
  resetFilters,
  adFiltersList
}) {
  const [searchCount, setSerchCount] = useState(0);
  const { t, i18n } = useTranslation();
  const upload_list = useSelector((state) => state.withoutVin.withoutVin);
  let responce = data && Object.keys(data).length !== 0 ? data : homebanner;
  const language = useSelector((state) => state.auth.language);
  const lang2 =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const navigate = useNavigate();
  return (
    <div
      class="tab-pane fade"
      id="tab2"
      role="tabpanel"
      aria-labelledby="tab2-tab"
    >
      <form>
        <div class="row">
          <div class="col-6">
            {/* <DynamicSelectComponent
              values={cities}
              filterData={filterData}
              setFilterData={setFilterData}
              placeholder={t("Cities")}
              name={"city"}
            /> */}
            <SelectOptions
              selectedOption={filterData.city}
              placeholder={t("Cities")}
              name="cities"
              onChange={(e) =>
                setFilterData({ ...filterData, city: [e.target.value] })
              }
              style={"form-control"}
              values={adFiltersList?.cities || []}
            />
          </div>
          <div class="col-6">
            {/* <DynamicSelectComponent
              optionsBuilt={makes}
              filterData={filterData}
              setFilterData={setFilterData}
              placeholder={t("Make")}
              name={"make"}
            /> */}
            {/* <SelectOptions
              selectedOption={filterData.make}
              placeholder={t("Make")}
              onChange={(e) =>
                setFilterData({ ...filterData, make: e.target.value })
              }
              style={"form-control"}
              values={makes}
            /> */}
            <SelectCustomItems
              selectedOption={filterData.make}
              items={adFiltersList?.make || []}
              // items1={makes?.slice(0, 5)}
              items2={adFiltersList?.make || []}
              // title1={"Most searched for"}
              title2={"All makes"}
              placeholder={t("Make")}
              handelChange={(e) =>
                setFilterData({ ...filterData, make: e?.value })
              }
              width={"100%"}
              hasAny
            />
          </div>
          <div class="col-6">
            {/* <DynamicSelectComponent
              optionsBuilt={models}
              filterData={filterData}
              setFilterData={setFilterData}
              placeholder={t("Model")}
              name={"model"}
              disabled={!filterData.make}
            /> */}
            <SelectOptions
              selectedOption={filterData.model}
              placeholder={t("Model")}
              onChange={(e) =>
                setFilterData({ ...filterData, model: e.target.value })
              }
              style={"form-control"}
              values={models}
              disabled={models?.length == 0}
            />
          </div>
          <div class="col-6">
            <SelectOptions
              selectedOption={filterData.bodyType}
              placeholder={t("Body Type")}
              onChange={(e) => {
                if (e.target.value == t("Any")) {
                  setFilterData({
                    ...filterData,
                    bodyType: [],
                  });
                } else {
                  setFilterData({
                    ...filterData,
                    bodyType: [
                      carTypes.filter(
                        (v) => v?.name?.[lang2] === e.target.value
                      )?.[0]?.name?.["en"],
                    ],
                  });
                }
              }}
              name={"bodyType"}
              style={"form-control"}
              values={adFiltersList?.body_type || []}
            />
          </div>
          <div class="col-6">
            <SelectOptions
              selectedOption={filterData?.price?.min}
              placeholder={t("Min Price")}
              onChange={(e) => {
                if (e.target.value == t("Any")) {
                  setFilterData({
                    ...filterData,
                    price: { ...filterData.price, min: "" },
                  });
                } else {
                  setFilterData({
                    ...filterData,
                    price: { ...filterData.price, min: e.target.value },
                  });
                }
              }}
              name={"minPrice"}
              style={"form-control"}
              // values={filter.data.sideBar.min_max_price.min.data}
              values={showFilteredValues(
                "min_max_price",
                filter.data.sideBar.min_max_price.min.data,
                filterData?.price?.max,
                "min"
              )}
            />
          </div>
          <div class="col-6">
            <SelectOptions
              selectedOption={filterData?.price?.max}
              placeholder={t("Max Price")}
              onChange={(e) => {
                if (e.target.value == t("Any")) {
                  setFilterData({
                    ...filterData,
                    price: { ...filterData.price, max: "" },
                  });
                } else {
                  setFilterData({
                    ...filterData,
                    price: { ...filterData.price, max: e.target.value },
                  });
                }
              }}
              name={"maxPrice"}
              style={"form-control"}
              // values={filter.data.sideBar.min_max_price.max.data}
              values={showFilteredValues(
                "min_max_price",
                filter.data.sideBar.min_max_price.max.data,
                filterData?.price?.min,
                "max"
              )}
            />
          </div>
          <div class="col-sm-12">
            <Button
              type="submit"
              disabled={!filterData.make}
              onClick={() =>
                navigate("/BuyCar", {
                  state: { filters: { ...filterData, brandNew: true } },
                })
              }
              style="btn"
              title={t("Search") + " " + t("cars")}
            />
          </div>
          <div class="col-6">
            <Button
              style="bnnr-btn"
              onClick={(e) => {
                e.preventDefault();
                resetFilters();
              }}
              title={
                <>
                  <img src={"assets/images/reset_settings.png"} />
                  {t("Reset settings")}
                </>
              }
            />
          </div>
          <div class="col-6 text-end">
            <Button
              onClick={() =>
                navigate("/AdvancedSearch?brandNew=1", {
                  state: { selectedOption: "Cars" },
                })
              }
              style="bnnr-btn"
              title={
                <>
                  <img src={"assets/images/advanced_filters.png"} />
                  {t("Advanced filters")}
                </>
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
}
