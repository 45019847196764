import AddCar from "../../../../Tools/Forms/AddCar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditVehicledataFetch } from "../../../../../Reducer/myvehicleSlice";
import { title } from "../../../../../data/content";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function EditVehicle() {
  const dispatch = useDispatch();
  const submitting = useSelector((state) => state.myvehicle.editdata);
  const addForm1 = [];
  const location = useLocation()
  
  useEffect(() => {
    dispatch(EditVehicledataFetch());
  }, [dispatch]);
  const { t, i18n } = useTranslation();
  console.log(location?.state)
  const back = false;
  const page = location?.state?.adTypeName?.['en'] === 'Rent' ? 'RentYourCar' : 'SellYourCar'
  var length = Object.keys(submitting).length;
  document.title = title.Myvehicles[i18n.language];
  // const url = "/Myaccount/Vehicle";
  
  return (
    <div className="sell_your edit_your_car_details">
      {length > 0 ? (
        <AddCar
          back={back}
          setData={addForm1 ? addForm1 : []}
          data={location?.state}
          page={page}
          adId={location?.state?._id}
          entityId={location?.state?._id}
        />
      ) : (
        "No data found"
      )}{" "}
    </div>
  );
}
