import React from "react";
import { title } from "../../../data/content";
import EnquiryForm from "./EnquiryForm";
import { useTranslation } from "react-i18next";
export default function Enquiry() {
  const { t, i18n } = useTranslation();
  document.title = title.Enquiry[i18n.language];

  return (
    <div>
      <section class="login enquiry">
        <div class="container">
          <EnquiryForm />
        </div>
      </section>
    </div>
  );
}
