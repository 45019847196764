import React from "react";
import { useTranslation } from "react-i18next";
import BannerSection from "./BannerSection";
import PlaceSection from "./PlaceSection";
import SearchedSection from "./SearchedSection";
import ArticlesSection from "../../Tools/ArticleBlog/ArticlesSection";
import Follow from "./Follow";
import BrandNewSection from "./BrandNewSection";
import { title } from "../../../data/content";
import BrandSection from "../../Tools/BrandSection";
import RecentlyCarView from "../../Tools/RecentCarView";
import { useEffect } from "react";
import { useState } from "react";
import { getEntityTypes, getMake } from "../../../Reducer/api";
import WelcomeModal from "../../Tools/WecomeModal";
import { useDispatch, useSelector } from "react-redux";
import { myprofilefetch } from "../../../Reducer/myprofileSlice";
import { changeLanguage } from "../../../Reducer/authSlice";
export default function Home({ page }) {
  const { t, i18n } = useTranslation();
  const profileData = useSelector((state) => state.myprofile.profilearr);
  const [carTypes, setCarTypes] = useState([]);
  const dispatch = useDispatch();
  const [selectedCarType, setSelectedCarType] = useState(null);
  let rtl = false;
  let lang = "ar";
  switch (i18n.language) {
    case "English":
      rtl = false;
      lang = "en";
      break;
    case "Arabic":
      rtl = true;
      lang = "ar";
      break;
    case "Kurdish":
      rtl = true;
      lang = "ku";
      break;
  }
  document.title = title.Home[i18n.language];
  const getCarTypes = async () => {
    const res = await getEntityTypes();
    setCarTypes(res?.data);
    // setSelectedCarType(res?.data?.[0]);
  };
  useEffect(() => {
    getCarTypes();
    if (localStorage.getItem("token")) {
      dispatch(myprofilefetch());
    }
  }, []);
  useEffect(() => {
    if (profileData?.userType) {
      if (profileData?.userType === "dealer") {
        if (
          !profileData?.additionalInfo?.dealerWorkingHours ||
          (
            // profileData?.email &&
            // profileData?.email !== "N/A" &&
            profileData?.emailVerified == false) ||
          profileData?.phoneNumberVerified == false
        ) {
          openModal();
        }
      } else {
        if (
          !profileData?.isWelcomeMailSent ||
          (
            // profileData?.email &&
            // profileData?.email !== "N/A" &&
            profileData?.emailVerified == false) ||
          profileData?.phoneNumberVerified == false
        ) {
          openModal();
        }
      }
    }
  }, [profileData]);

  const [modal, setModal] = useState(false);
  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  
  return (
    <>
      <BannerSection carTypes={carTypes} page={page} />

      <BrandNewSection rtl={rtl} page={page} />

      <BrandSection brandTitle={t("Browse by brand")} page={page} />
      <PlaceSection page={page} />
      <SearchedSection
        rtl={rtl}
        page={page}
        carTypes={carTypes}
        setCarTypes={setCarTypes}
        selectedCarType={selectedCarType}
        setSelectedCarType={setSelectedCarType}
      />
      <ArticlesSection rtl={rtl} page={page} />
      <RecentlyCarView
        rtl={rtl}
        page={page}
        title={"Recently viewed car deals"}
      />
      <Follow />
      <WelcomeModal
        modal={modal}
        closeModal={closeModal}
        userType={profileData?.userType}
      />
    </>
  );
}
