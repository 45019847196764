import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Tools/Button";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorSms, phoneRegExp } from "../../../data/content";
import { registerForm } from "../../../Reducer/authSlice";
import FormInput from "../../Tools/FormInput";
import { toast } from "react-toastify";
import ListItem from "../../Tools/ListItem";
import { apiManager } from "../../../api/connectAPI";
import PhoneNumberInput from "../../Tools/PhoneNumberInput";
import VerifyWithOtp from "../VerifyWithOtp";
import {
  sendResetPasswordOTP,
  sendVerificationOTP,
} from "../../../Reducer/api";
import { convertDigitsToEnglish } from "../../../utils/constants";
import CustomPhoneInput from "../../Tools/CustomPhoneInput";
import ReCAPTCHA from "react-google-recaptcha";

export default function SignupForm({ type }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitting = useSelector((state) => state.auth.submitting);
  const registers = useSelector((state) => state.auth.auth);
  const token = useSelector((state) => state.auth.token);
  const error = useSelector((state) => state.auth.error);
  const success = useSelector((state) => state.auth.success);
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";
  const langCaptcha =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ckb";
  const [captchaCode, setCaptchaCode] = useState("");
  const [loading, setLoading] = useState(false);

  //Modal states
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const openModal = (type, identifier, defaultAlert) => {
    setModalData({
      type: type,
      identifier: identifier,
      defaultAlert: defaultAlert,
    });
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
    navigate("/Details");
  };

  let move =
    success && registers?.form && registers?.auth != {} ? registers?.form : "";
  const [identifier, setIdentifier] = useState("");
  const verificationPopup = () => {
    const title = type === "email" ? "Email" : "Phone Number";
    openModal(title, identifier, "");
    // const t = type === "email" ? "Email" : "Phone Number";
    // sendVerificationOTP({
    //   [type === "email" ? "email" : "phoneNumber"]: identifier,
    // }).then((res) => {
    //   if (res.status) {
    //     openModal(t, identifier, res.message);
    //   } else {
    //     setError('email', {message: res.message})
    //     setPhoneNumberErrors(res.message)
    //     // openModal(t, identifier, res.message);
    //     navigate("/Details");
    //   }
    // });
  };

  useEffect(() => {
    switch (move) {
      case "pending":
        verificationPopup();
        break;
      case "clear":
        navigate("/");
        break;
    }
  }, [move]);

  const schema = Yup.object().shape({
    email:
      type == "email"
        ? Yup.string()
            .required(t("Email is a required"))
            .email(t("Please Enter Valid email address"))
        : Yup.string(),
    phoneNumber:
      type == "phone"
        ? Yup.string()
            .min(9, t("Phone number is not valid"))
            .max(16, t("Phone number is not valid"))
        : Yup.string(),
    password: Yup.string()
      .required(t("Password is a required field"))
      .min(6, t("Password must be at least 6 characters"))
      .max(30, t("Password must be at most 30 characters")),
    confirmPassword: Yup.string()
      .required(t("Password is a required field"))
      .label(t("Confirm Password"))
      .test(
        "match",
        t("Your Password Doesn't Match"),
        (v) => v === getValues("password")
      ),
    type: Yup.string(),
  });

  const {
    register,
    getValues,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      phoneNumber: "",
    },
  });

  const onError = (errors) => {
    console.log(errors);
  };

  const onSubmit = async (data) => {
    // data["phone"] = convertDigitsToEnglish(phoneNumber);
    console.log(data);
    setLoading(true);
    if (submitting == false) {
      const attribute = type === "email" ? "email" : "phoneNumber";
      const value = type === "email" ? data.email : data.phoneNumber;
      setIdentifier(value);
      console.log(type);
      apiManager(
        "GET",
        `general/checkAvailability?lang=${lang}&attribute=${attribute}&value=${value}`
      )
        .then((res) => {
          if (res.status) {
            let fullData = {};
            if (type === "email") fullData.email = data.email;
            if (type === "phone") fullData.phoneNumber = data.phoneNumber;
            fullData = {
              ...fullData,
              language: i18n.language,
              password: data.password,
              userType: selectedUserType === "personal" ? "user" : "dealer",
              token: captchaCode
            };
            dispatch(registerForm({ json: fullData, setLoading: setLoading }));
          } else {
            setError("email", { message: res.message });
            setError("phoneNumber", { message: res.message });
            // setPhoneNumberErrors(res.message);
            toast.error(res.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong!");
          setError("email", { message: t("Something went wrong") });
          setError("phoneNumber", { message: t("Something went wrong") });
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (error) {
      if (type === "phone") {
        // setPhoneNumberErrors(t(error?.payload));
        setError("phoneNumber", { message: error.payload });
      } else {
        console.log(typeof error.payload);
        setError("email", { message: error.payload });
      }
    }
  }, [error]);

  const [selectedUserType, setSelectedUserType] = useState("personal");

  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [phoneNumberErrors, setPhoneNumberErrors] = useState("");

  const handleCaptureCaptcha = (code) => {
    console.log(code);
    setCaptchaCode(code);
  };
  return (
    <div style={{ position: "relative" }}>
      <form onSubmit={captchaCode ? handleSubmit(onSubmit, onError) : null}>
        <div
          class="tab-pane fade show active"
          id="With_Phone"
          role="tabpanel"
          aria-labelledby="With_Phone-tab"
        >
          {type === "email" ? (
            <div class="form-group">
              <label>{t("Email")}*</label>
              <FormInput
                style={"form-control"}
                intype="email"
                event={register}
                name="email"
                placeholder={t("Please Enter the Email Address")}
              />
              {errors.email ? <p>{errors.email.message}</p> : ""}
            </div>
          ) : (
            <div className="mb-2">
              <label>{t("Phone")}*</label>
              {/* <PhoneNumberInput
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                phoneNumberErrors={phoneNumberErrors}
                setPhoneNumberErrors={setPhoneNumberErrors}
              /> */}
              <div className="form-group">
                <CustomPhoneInput
                  event={register}
                  name={"phoneNumber"}
                  setValue={setValue}
                  getValues={getValues}
                />
                {errors.phoneNumber ? (
                  <p>{t(errors.phoneNumber.message)}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}

          {/* <div class="form-group">
            <label>{type == "email" ? t("Email") : t("Phone")}*</label>
            <FormInput
              style={"form-control"}
              intype={type == "phone" ? "number" : "email"}
              event={register}
              name={type}
              placeholder={
                type == "email"
                  ? t("Please Enter the Email Address")
                  : t("Please Enter the Phone Number")
              }
            />
            {errors.email ? (
              <p>{t("Email is a required field")}</p>
            ) : (
              errors.phone && <p>{t("Phone number is not valid")}</p>
            )}
          </div> */}

          <div class="form-group psd">
            <label>{t("Password")}*</label>
            <span class="password d-block">
              <FormInput
                style={"form-control"}
                intype={"password"}
                event={register}
                name={"password"}
                placeholder={t("Please Enter The Password")}
              />
            </span>
            {errors.password && <p>{errors.password.message}</p>}
          </div>
          <div class="form-group psd" style={{ marginBottom: "100px" }}>
            <label>{t("Confirm Password")}*</label>
            <span class="password d-block">
              <FormInput
                style={"form-control"}
                intype={"password"}
                event={register}
                name={"confirmPassword"}
                placeholder={t("Please Enter The Password")}
              />
            </span>
            {errors.confirmPassword && <p>{errors.confirmPassword.message}</p>}
          </div>
          <ReCAPTCHA
            hl={langCaptcha}
            sitekey={process.env.REACT_APP_WEBSITE_KEY}
            onChange={handleCaptureCaptcha}
          />
          <Button
            disabled={loading || !captchaCode}
            style="btn"
            title={loading === false ? t("Register") : t("Please wait...")}
            type="submit"
          />
        </div>
      </form>
      <div style={{ position: "absolute", bottom: "135px", width: "100%" }}>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <ListItem
            innerhtmlType={"button"}
            key={0}
            btnType={"button1"}
            onClick={() => setSelectedUserType("personal")}
            style={"nav-item"}
            buttonStyle={
              selectedUserType == "personal" ? "nav-link active" : "nav-link"
            }
            buttonTitle={t("Personal")}
          ></ListItem>
          <ListItem
            innerhtmlType={"button"}
            key={1}
            btnType={"button2"}
            onClick={() => setSelectedUserType("dealer")}
            style={"nav-item"}
            buttonStyle={
              selectedUserType == "dealer" ? "nav-link active" : "nav-link"
            }
            buttonTitle={t("Dealer")}
          ></ListItem>
        </ul>
      </div>
      <VerifyWithOtp
        modal={modal}
        openModal={openModal}
        handleClose={handleClose}
        data={modalData}
        presetCode={captchaCode}
        setPresetCode={setCaptchaCode}
      />
    </div>
  );
}
