import Basicsfilters from "./Basicsfilters";
import DealersList from "./DealersList";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { title } from "../../../data/content";
import { dealerList } from "../../../Reducer/dealerFilterSilce";
import ShortBy from "./ShortBy";
import { NavLink, useNavigate } from "react-router-dom";
import filterData from "../../../data/dealerFilterSlice.json";
import { itemLimit } from "../../../utils/constants";
import { getDealerCitites } from "../../../Reducer/api";
export default function Dealer_search() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let rtl = false;
  switch (i18n.language) {
    case "English":
      rtl = false;
      break;
    case "Arabic":
      rtl = true;
      break;
    case "Kurdish":
      rtl = true;
      break;
  }
  const [loading, setLoading] = useState(true);
  document.title = title.dealerpage[i18n.language];
  const dispatch = useDispatch();
  const responce = useSelector((state) => state.dealersList.list);
  const [sortVal, setSortVal] = useState({
    sort: "",
    order: "",
  });
  const auth = useSelector((state) => state.auth.auth);
  let dataId = { id: auth && auth.id ? auth.id : "" };
  const [currentPage, setCurrentPage] = useState(0);
  const [dealerCities, setDealerCities] = useState(null);
  const [filters, setFilters] = useState({ city: "", name: "" });

  useEffect(() => {
    setLoading(true);
    dispatch(
      dealerList({
        dataId: dataId,
        page: currentPage + 1,
        itemLimit: itemLimit,
        setLoading: setLoading,
        filters: filters,
        sort: sortVal.sort.concat(sortVal.order ? "," + sortVal.order : ""),
      })
    );
  }, [
    dispatch,
    filters.name.length,
    filters.city,
    filters.distance,
    sortVal,
    currentPage,
  ]);

  let length = Object.keys(responce).length;

  //   const len=Object.keys(responce.dealers).length;

  console.log(filters);

  const previous = () => {
    let page = currentPage;
    if (page > 0) setCurrentPage(page - 1);
  };
  const next = () => {
    let page = currentPage;
    if (page < responce.totalPages - 1) setCurrentPage(page + 1);
  };

  useEffect(() => {
    getDealerCitites().then((res) => {
      if (res.status) {
        setDealerCities(res?.data);
      }
    });
  }, []);

  return (
    <section class="advance_search car_search_results dealer">
      <div class="container">
        <h6 className="back">
          <a onClick={() => navigate(-1)}>
            <img src="../assets/images/backbt.png" /> {t("Go back")}
          </a>
        </h6>
        <div class="advance_search_in">
          <ShortBy previous={previous} next={next} setSortVal={setSortVal} />
          <Basicsfilters
            city={filterData.data.filter}
            dealerCities={dealerCities}
            distance={filterData.data.Distancefilter}
            responce={responce.docs}
            filters={filters}
            setFilters={setFilters}
          />
          {loading ? (
            <div
              class="d-flex justify-content-center"
              style={{ minHeight: "100vh", marginTop: "25vh" }}
            >
              <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <DealersList
              responce={responce.docs}
              pageCount={responce.totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>
      </div>
    </section>
  );
}
