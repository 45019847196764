import React, { useEffect, useState } from "react";
import ListItem from "../../../../Tools/ListItem";
import { sideBarList } from "../../content";
import { useTranslation } from "react-i18next";

import { clearToken, clearUser } from "../../../../../Reducer/authSlice";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";

export default function Sidebar({ component }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setPage] = useState("My Account");

  useEffect(() => {
    console.log(location);
    let index = -1;
    let item = sideBarList.filter((v, i) => {
      if (location.pathname.endsWith(v.segment)) {
        index = i;
        return true;
      }
    })?.[0];
    if(!item || item?.length == 0) {
      item = sideBarList[0]
      index = 0
    }
    console.log("item", item, "index", index);
    handleClick(item, index)
  }, []);

  const handleClick = (item, index) => {
    console.log(item);
    console.log(index);
    $(".nav-link").removeClass("active");
    $("#gt-" + index).addClass("active");
    setPage(item.name);
    if (item.action) {
      dispatch(clearUser());
      dispatch(clearToken());
      window.location.reload();
      navigate("/");
    } else {
      navigate(`/MyAccount/${item.segment}`);
    }
    closeMenu();
  };
  const closeMenu = () => {
    $(".sidebar").removeClass("main");
  };

  return (
    <section class=" close_amountmain ">
      <div class="container">
        <h2>
          <span className="d-none d-md-block">{t("My Account")} </span>{" "}
          <span className="d-block d-md-none">{t(currentPage)}</span>{" "}
          <span
            class="filtermenu d-block d-lg-none mt-0"
            onClick={() => $(".sidebar").addClass("main")}
          >
            <img src="../assets/images/filtermenu.png" alt="pencil" />
          </span>
        </h2>
        <div class="my_account   ">
          <div class="sidebar">
            <span class="filtermenu d-block d-lg-none" onClick={closeMenu}>
              <i class="fa-solid fa-xmark"></i>
            </span>
            <ul>
              {sideBarList && sideBarList.length > 0
                ? sideBarList.map((item, index) => {
                    return (
                      <ListItem
                        value={
                          <a
                            className="nav-link "
                            id={`gt-${index}`}
                            onClick={() => handleClick(item, index)}
                          >
                            {t(item.name)}
                          </a>
                        }
                        innerhtmlType={"html"}
                      ></ListItem>
                    );
                  })
                : ""}
            </ul>
          </div>
          {component}
        </div>
      </div>
    </section>
  );
}
