import Datatable from "../../../../Tools/Datatable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { transactionsDetails } from "../../../../../Reducer/transactionsSlice";
import { tableColumn } from "../../content";
import { title } from "../../../../../data/content";
import Pagination from "../../../../Tools/Pagination";

export default function Transactions() {
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const data = useSelector((state) => state.transaction.transactionArr);
  const submitting = useSelector((state) => state.transaction.submitting);
  const meta = data?.transactions || [];
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const { t, i18n } = useTranslation();

  //pagination page change (page number wise)
  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  useEffect(() => {
    dispatch(transactionsDetails({ page: currentPage + 1 }));
  }, [dispatch, currentPage]);

  document.title = title.Transactions[i18n.language];

  if (submitting) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}
      >
        <div class="spinner-grow" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div>
      {meta?.length > 0 ? (
        <>
          <Datatable
            data={meta ? meta : []}
            column={tableColumn ? tableColumn : []}
            title={"Transactions"}
            submitting={submitting}
          />
          <nav aria-label="Page navigation example">
            <Pagination
              style={`pagination float-end mb-5 mt-4 ${
                lang !== "en" && "paginationButtonAr"
              }`}
              next={"Next"}
              forcePage={currentPage}
              previous={"Previous"}
              pageCount={data?.totalPages}
              rangeDisplayed={3}
              handlePageClick={handlePageClick}
            />
          </nav>
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <h5 style={{ fontWeight: "500" }} className="text-center">
            {t("No transactions found")}
          </h5>
        </div>
      )}
    </div>
  );
}
