import { NavLink, Navigate, useLocation } from "react-router-dom";
import Pages from "./Pages";
import { useTranslation } from "react-i18next";
import { appLinks, social } from "../../../data/content";
import ListItem from "../../Tools/ListItem";
import Button from "../../Tools/Button";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { saveContactUsForm } from "../../../Reducer/ConteactUsSilce";
import { fetchdescribe } from "../../../Reducer/ConteactUsSilce";
import { toast } from "react-toastify";
import { errorSms } from "../../../data/content";
import FormInput from "../../Tools/FormInput";
import PhoneNumberInput from "../../Tools/PhoneNumberInput";
import { useDispatch, useSelector } from "react-redux";
import CustomPhoneInput from "../../Tools/CustomPhoneInput";
import ReCAPTCHA from "react-google-recaptcha";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  let mainClass = "main-footer";
  switch (location.pathname) {
    case "/Signup":
      mainClass = "main-footer lgin_footer";
      break;
    case "/Login":
      mainClass = "main-footer lgin_footer";
      break;
    case "/Details":
      mainClass = "main-footer lgin_footer";
      break;
  }
  const langCaptcha =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ckb";
  const [captchaCode, setCaptchaCode] = useState("");
  const error = useSelector((state) => state.contactus.error);
  const success = useSelector((state) => state.contactus.success);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const handleModalClose = () => {
    setModal(false);
    // setPhoneNumber("");
    // setPhoneNumberErrors("");
    reset();
  };
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, t("Full name must be at least 3 characters"))
      .max(30, t("Full name must be at most 30 characters"))
      .required(t("Your name is a required field")),
    number: Yup.string(),
    email: Yup.string().email(),
    message: Yup.string().required(t("message is required")),
    phoneNumber: Yup.string()
      .min(9, t("Phone number is not valid"))
      .max(16, t("Phone number is not valid")),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      phoneNumber: "",
    },
  });

  const onError = (errors) => {
    console.log(errors);
  };

  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [phoneNumberErrors, setPhoneNumberErrors] = useState("");

  const onSubmitform = async (data) => {
    // setSuccessMessage("");
    if (loading == false) {
      // if (phoneNumberErrors) return;

      const apiData = {
        name: data.fullname,
        phoneNumber: data.phoneNumber,
        email: data.subject,
        message: data.message,
        role: "feedback",
        about: "feedback",
      };

      dispatch(
        saveContactUsForm({
          formData: apiData,
          setLoading: setLoading,
          reset: handleModalClose,
        })
      );
      toast.success("Form Submit successfully");
    }
  };

  const openLink = (type) => {
    const link = appLinks?.[type];
    window.open(link);
  };
  const handleCaptureCaptcha = (code) => {
    console.log(code);
    setCaptchaCode(code);
  };
  return (
    <footer class={mainClass}>
      <div class="container">
        <div class="row">
          <div class=" col-lg-12">
            <NavLink to="/">
              <img
                class="footer_logo"
                src={`../assets/images/${t("MainIcon")}`}
                alt="logo"
              />
            </NavLink>
          </div>
          <Pages />
          <div class="col-6 col-lg-3 set help">
            <h3>{t("Help us improve")}</h3>
            <Button
              title={t("Send us feedback")}
              onClick={() => setModal(true)}
              style="feedback p-0"
            />
            <ul class="social">
              {social.map((item, key) => {
                return (
                  <ListItem
                    key={key}
                    innerhtmlType={"html"}
                    value={
                      <a href={item.link}>
                        <i class={item.icon}></i>
                      </a>
                    }
                  ></ListItem>
                );
              })}
            </ul>
            <div class="row app-cards">
              <div role="button" class="col-6 col-lg-6">
                <img
                  onClick={() => openLink("apple")}
                  class="w-100"
                  src="../assets/images/apple-store.svg"
                  alt="app"
                />
              </div>
              <div role="button" class="col-6 col-lg-6">
                <img
                  onClick={() => openLink("google")}
                  className="w-100"
                  src="../assets/images/google-store.svg"
                  alt="app"
                />
              </div>
              <div role="button" class="col-6 col-lg-6">
                <img
                  onClick={() => openLink("huawei")}
                  className="w-100"
                  src="../assets/images/huawei-store.svg"
                  alt="app"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={handleModalClose} centered size="lg">
        <ModalHeader toggle={handleModalClose}>
          <h5 className="text-center">{t("Send us feedback")}</h5>
        </ModalHeader>
        <ModalBody className="support_enquiry" style={{ marginBlock: "0px" }}>
          <form onSubmit={handleSubmit(onSubmitform, onError)}>
            <div class="row m-2">
              <div class="col-sm-6 my-4">
                <div class="form-group">
                  <label>{t("Full Name")}</label>
                  <FormInput
                    style={"form-control"}
                    intype={"text"}
                    event={register}
                    name={"fullname"}
                    placeholder={t("Your Name")}
                  />
                  {errors.fullname && (
                    <p className="error">{t(errors.fullname.message)}</p>
                  )}
                </div>
              </div>
              <div class="col-sm-6 my-4">
                <div class="form-group">
                  <label>{t("Phone Number")}</label>
                  {/* <PhoneNumberInput
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    phoneNumberErrors={phoneNumberErrors}
                    setPhoneNumberErrors={setPhoneNumberErrors}
                    styles={{height: '36px'}}
                    errorStyles={{bottom: '-16px'}}
                  /> */}
                  <CustomPhoneInput
                    event={register}
                    name={"phoneNumber"}
                    setValue={setValue}
                    getValues={getValues}
                  />
                  {errors.phoneNumber ? (
                    <p className="error">{t(errors.phoneNumber.message)}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group mb-4">
                  <label>{t("Email (Optional)")}</label>
                  <FormInput
                    style={"form-control"}
                    intype={"text"}
                    event={register}
                    name={"subject"}
                    placeholder={t("Email")}
                  />
                  {errors.subject && (
                    <p className="error">{t(errors.subject.message)}</p>
                  )}
                </div>
              </div>
              <div class="col-sm-12 mb-4">
                <div class="form-group">
                  <label>{t("Your Message")}</label>
                  <FormInput
                    style={"form-control"}
                    intype={"text"}
                    event={register}
                    name={"message"}
                    placeholder={t("Type your message")}
                  />
                  {errors.message && (
                    <p className="error">{t(errors.message.message)}</p>
                  )}
                </div>
              </div>
              <div class="col-sm-12 d-flex mb-3">
                <ReCAPTCHA
                  hl={langCaptcha}
                  sitekey={process.env.REACT_APP_WEBSITE_KEY}
                  onChange={handleCaptureCaptcha}
                />
              </div>
              <div class="col-sm-12 d-flex">
                <Button
                  disabled={loading || !captchaCode}
                  style="float-end themeButton"
                  title={loading ? t("Please wait...") : t("Submit")}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </footer>
  );
}
