import { t } from "i18next";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../Tools/Button";
import { apiManager } from "../../../api/connectAPI";
import i18n from "../../../i18n";
import FormInput from "../../Tools/FormInput";
import { sendResetPasswordOTP } from "../../../Reducer/api";

//form utils imports
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";

export default function EnterOTP() {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const [otpCount, setOtpCount] = useState(1);

  const lang = localStorage.getItem("cl") === "English" ? "en" : "ar";
  const identifier = location.state;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue("otp", otp);
  }, [otp]);

  const resendOTP = async () => {
    if (!submitting) {
      if (otpCount < 3) {
        setOtpCount(otpCount + 1);
        setSubmitting(true);
        const res = await sendResetPasswordOTP({...identifier, type: 3});
        if (res.status) {
          toast.success(res.message);
          setSubmitting(false);
        } else {
          toast.error(res.message);
          setSubmitting(false);
        }
      } else {
        toast.warn("Verification codes exhausted");
        setSubmitting(false);
      }
    }
  };

  const schema = Yup.object().shape({
    otp: Yup.string(),
    password: Yup.string()
      .required(t("Password is a required field"))
      .min(6, t("Password must be at least 6 characters"))
      .max(30, t("Password must be at most 30 characters")),
    confirmPassword: Yup.string()
      .test(
        "matchPassword",
        t("Confirm password does not match"),
        (v) => v === getValues("password")
      )
      .required(t("Confirm Password is required"))
      .label(t("Confirm Password")),
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  console.log(errors);
  const handleVerification = (newPass) => {
    if (otp.length < 6) {
      setError("otp", { message: t("Enter code") });
      toast.warn(t("Invalid Verification Code"));
      return;
    }
    setLoading(true);
    const data = {
      otp: otp,
      identifier: identifier?.email ? identifier.email : identifier.phoneNumber,
      type: identifier?.phoneNumber ? "Text" : "Email",
      password: newPass.password,
    };

    apiManager("POST", `auth/resetPassword?lang=${lang}`, data)
      .then((res) => {
        if (res.status) {
          toast.success(res.message);
          navigate("/Login");
          console.log(res);
          setLoading(false);
        } else {
          toast.error(res.message);
          setLoading(false);
          console.log(res.message);
          setError("otp", res.message);
          setError("otp", { message: res.message });
        }
      })
      .catch((err) => {
        toast.error(err.message);
        console.error(err);
        setLoading(false);
      });
  };
  return (
    <section className="login">
      <div className="container">
        <div className="form-signin">
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            {identifier?.email
              ? t("A one time code has been sent to your email") +
                " - " +
                identifier?.email
              : t("A one time code has been sent to your phone number") +
                " - " +
                identifier?.phoneNumber}
          </p>
          <h1
            style={{
              marginBottom: "-30px",
              marginTop: "30px",
              fontSize: "25px",
            }}
          >
            {t("Enter code")}
          </h1>
          <div className="form-group mt-5">
            <OTPInput
              inputStyle={{
                width: "100%",
                height: "35px",
                borderRadius: "5px",
                marginInline: "10px",
              }}
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <p className="mt-2 mx-2" style={{ position: "unset" }}>
              {errors.otp && <p>{t(errors.otp.message)}</p>}
            </p>

            <div style={{ textAlign: "right" }}>
              <a className="mt-3 mx-2" onClick={resendOTP}>
                {t("Resend Verification Code")}
              </a>
            </div>
          </div>
          <div class="form-group mt-5">
            <label>{t("New Password")}*</label>
            <span class="password d-block">
              <FormInput
                style={"form-control no-zoom"}
                intype={"password"}
                event={register}
                name={"password"}
                placeholder={t("New Password")}
              />
              <i class="far fa-eye"></i>
            </span>

            {errors.password && <p>{t(errors.password.message)}</p>}
          </div>

          <div class="form-group" style={{ marginBlock: "25px" }}>
            <label>{t("Confirm Password")}*</label>
            <span class="password d-block">
              <FormInput
                style={"form-control no-zoom"}
                intype={"password"}
                event={register}
                name={"confirmPassword"}
                placeholder={t("Confirm Password")}
              />
              <i class="far fa-eye"></i>
            </span>

            {errors.confirmPassword && (
              <p>{t(errors.confirmPassword.message)}</p>
            )}
          </div>

          <div className=" d-flex justify-content-evenly w-100">
            <Button
              onClick={handleSubmit(handleVerification)}
              title={loading ? t("Please wait...") : t("Continue")}
              style="btn btn-primary"
              disabled={loading}
            />
          </div>
          <div className=" d-flex justify-content-evenly w-100">
            {/* <NavLink to="/MyAccount/MyProfile"  >{t("Cancel")}</NavLink> */}
            <Button
              onClick={() => navigate("/Login")}
              title={t("Cancel")}
              style="w-100 rounded-1 my-2 fw-semibold fs-6"
              inlineStyle={{
                backgroundColor: "transparent",
                height: "45px",
                border: "1px solid #989898",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
