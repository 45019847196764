import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { likeDislikePost } from "../../../Reducer/api";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchSaved } from "../../../Reducer/savedSlice";
import { useTranslation } from "react-i18next";
import {
  beginWithImage,
  formatPrice,
  isImageOrVideo,
  makeTagText,
} from "../../../utils/constants";
import { Spinner } from "reactstrap";

const OwlItemCars = ({
  responce,
  page,
  handleClick,
  onChange,
  selectedList,
  fill,
  unfill,
  iconText,
  checkBoxText,
  cars,
  rentType,
}) => {
  const token = useSelector((state) => state.auth.token);
  const language = useSelector((state) => state.auth.language);
  const { t } = useTranslation();
  const [responces, setResponces] = useState(responce);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const saved = useSelector((state) => state.saved.saved);
  const [listOfSaved, setListOfSaved] = useState([]);
  const [loading, setLoading] = useState(false);
  const [beingSaved, setBeingSavedId] = useState("");
  useEffect(() => {
    let newList = saved?.ads?.map((v) => v._id);
    setListOfSaved(newList);
  }, [saved]);

  useEffect(() => {
    if (token) {
      dispatch(fetchSaved({ setLoading: setLoading }));
    }
  }, []);
  const update = async (id, updatedData) => {
    return await responces.map((item) =>
      item.id === id ? { ...item, ...updatedData } : item
    );
  };

  const click = async (id, liked) => {
    if (token) {
      try {
        let data = { postId: id, like: liked };
        const val = { liked: liked };
        const result = await update(id, val);
        setResponces(result);
        let respo = await likeDislikePost(data);
        if (respo.message) {
          toast.success(respo.message);
        }
      } catch (e) {
        toast.error(e.responce ? e.responce.message : e.message);
      }
    }
    //handleClick(true);
  };
  const selectBox = async (items) => {
    let data = selectedList;
    const index = data.findIndex((obj) => obj.id === items.id);
    if (index !== -1) {
      onChange([
        ...data.slice(0, index),
        ...data.slice(index + 1, data.length),
      ]);
    } else {
      onChange([...data, items]);
    }
  };

  // const deleteSavedCar = async (adId) => {
  //     let res = await likeDislikePost(adId, 'remove');
  //     if(res) {
  //         dispatch(fetchSaved())
  //         setResponces([])
  //     }
  // }

  const toggleSaveCar = async (adId) => {
    if (!loading) {
      if (!localStorage.getItem("token")) {
        navigate("/login", {
          state: { backPage: location.pathname + location?.search },
        });
        return;
      }

      setLoading(true);
      setBeingSavedId(adId);
      const perform = listOfSaved?.includes(adId) ? "remove" : "add";
      const res = await likeDislikePost(adId, perform, setLoading);
      dispatch(fetchSaved());
    }
  };

  const getPrice = (item) => {
    let day = item?.adTypeName?.["en"] === "Sell" ? "normal" : rentType;
    switch (day) {
      case "normal":
        return `${formatPrice(lang, item?.askPrice)}`;
      case "daily":
        return `${formatPrice(lang, item?.askPrice)}/${t("Daily")}`;
      case "weekly":
        return `${formatPrice(lang, item?.askPrice1)}/${t("Weekly")}`;
      case "monthly":
        return `${formatPrice(lang, item?.askPrice2)}/${t("Monthly")}`;
      case "rental":
        return `${formatPrice(lang, item?.askPrice)}/${t("Daily")}`;
    }
  };

  const gotoSingleCar = (item) => {
    if (item?.adTypeName?.["en"] === "Sell") {
      navigate(`/SingleCar?adId=${item?._id}`);
    } else {
      navigate(`/SingleRental?adId=${item?._id}`);
    }
  };

  return (
    <>
      {cars &&
        cars?.map((item, key) => {
          const tagText = makeTagText(item, lang);
          let adImages = beginWithImage(item?.uploads);
          const type = isImageOrVideo(adImages?.[0]);
          return (
            <div class="item position-relative">
              <div class="set  overflow-hidden">
                {item?.owner?.userType !== "dealer" && (
                  <p className="owl-item-badge">
                    {" "}
                    <span
                      style={{
                        borderRadius:
                          lang !== "en" ? "0px 5px 0px 5px" : "5px 0px 5px 0px",
                        fontSize: "11px",
                        backgroundColor: "#334660",
                      }}
                      class=" text-light float-start px-4"
                    >
                      {t("Private ad")}
                    </span>
                  </p>
                )}
                <div
                  class="img overflow-hidden"
                  style={{ cursor: "pointer", borderRadius: "5px" }}
                  onClick={() => gotoSingleCar(item)}
                >
                  {type === "video" ? (
                    <video
                      controls={false}
                      className="rounded-2"
                      height={400}
                      style={{
                        objectFit: "cover",
                        marginBottom: "-7px",
                        width: "100%",
                      }}
                    >
                      <source
                        src={adImages?.[0] || "assets/images/notFound.png"}
                      />
                    </video>
                  ) : (
                    <img
                      src={adImages?.[0] || "assets/images/notFound.png"}
                      alt="blog1"
                      className={`small-icon`}
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  )}
                </div>
                <span className="d-flex justify-content-between my-2">
                  <h4
                    onClick={() => gotoSingleCar(item)}
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      cursor: "pointer",
                      position: "unset",
                      paddingBottom: '5px'
                    }}
                  >
                    {t(item?.properties?.basicDetails?.make?.[lang]) +
                      " " +
                      t(item?.properties?.basicDetails?.model?.[lang])}
                  </h4>
                  <span
                    className="px-2 owl-card-heart position-relative"
                    style={{ cursor: "pointer", zIndex: 99, height: "20px" }}
                    onClick={() => toggleSaveCar(item?._id)}
                  >
                    {loading && beingSaved === item?._id ? (
                      <Spinner
                        color="secondary"
                        size="sm"
                        type="grow"
                        className="position-absolute"
                        style={{ top: "2px", right: "10px" }}
                      >
                        Loading...
                      </Spinner>
                    ) : (
                      <img
                        height={20}
                        style={{ width: "unset" }}
                        src={`assets/images/${
                          listOfSaved?.includes(item?._id)
                            ? "heart_fill.png"
                            : "heart.webp"
                        }`}
                        alt="img"
                      />
                    )}
                  </span>
                </span>
                {/* <span className="fw-bold text-light bg-danger float-end px-1 rounded-1">
                  {item?.brandNew ? "Brand New" : ""}
                </span> */}
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => gotoSingleCar(item)}
                >
                  <p
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      height: "45px",
                    }}
                  >
                    {tagText}
                  </p>
                  {/* <span class="title match d-sm-block d-none" data-mh="title">
                    {t(item?.properties?.specifications?.fuelType?.[lang])}
                  </span> */}

                  <div className="d-flex justify-content-between">
                    {/* <h3>${item?.adTypeName?.['en'] === 'Sell' ? item?.entity?.properties?.price : item?.entity?.properties?.price?.daily.concat('/Day')}</h3> */}
                    <h3 className="cardSmall" style={{ paddingBottom: "5px" }}>
                      {getPrice(item)}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};
export default OwlItemCars;
