import React from "react";
import LeftRigtChat from "./LeftRigtChat";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { useRef } from "react";
import { toast } from "react-toastify";
import { apiManager } from "../../../../../api/connectAPI";
import { Icon } from "@iconify/react";
import { BeatLoader } from "react-spinners";
import { fetchMessages } from "../../../../../Reducer/api";
import { typeMessageAttatchments } from "../../../../../utils/types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";

export default function Chat({ data, selectedChat, loading, setLoading }) {
  const MicRecorder = require("mic-recorder-to-mp3");
  const [recorder, setMp3Recorder] = useState(
    new MicRecorder({
      bitRate: 128,
    })
  );
  // const recorder = new MicRecorder({
  //   bitRate: 128,
  // });

  const { t } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang2 =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";

  const [suggestion, setSuggestion] = useState(false);
  const udata = useSelector((state) => state.auth);
  const uploadType = typeMessageAttatchments;
  const [chatData, setChatData] = useState(null);
  const [message, setMessage] = useState("");
  const [file, setFile] = useState([]);
  const [fileType, setType] = useState("");
  const [recordingUrl, setRecordingUrl] = useState("");
  const [person, setPerson] = useState(null);
  const [replying, setReplying] = useState(null);
  const chatRef = useRef(null);
  const [sendLoading, setSendLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);

  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const handleCloseModal = () => {
    setModal(false);
    emptyFile();
    handleClosePopover();
  };

  const getMessages = async (chatId) => {
    if (chatId) {
      const res = await fetchMessages({
        chatId: chatId,
        currentPage: pageNumber,
      });
      setChatData([]);
      setChatData(res?.messages?.docs?.reverse());
      setPerson(res?.chat);
      setLoading(false);
      scrollChat();
    }
  };

  const scrollChat = () => {
    const element = chatRef?.current;

    element?.scrollIntoView({
      block: "nearest",
      inline: "start",
    });
  };

  useEffect(() => {
    setLoading(true);
    setChatData([]);
    setPageNumber(1);
    getMessages(selectedChat);
  }, [selectedChat]);

  const handleSendMessage = async (e, locationProp) => {
    if (e) e.preventDefault();
    const locationTmp = location || locationProp;
    if (file.length == 0 && message.length == 0 && !locationTmp) return;
    setSendLoading(true);

    let item = {
      chatId: selectedChat,
      message: message,
      messageMetadata: {
        type: "enquiry",
        enquiryType: "test drive",
        enquiryDate: new Date().valueOf(),
      },
      messageParentId: replying ? replying?.id : "",
      attachmentPath: "sdadasd",
      messageType: "text", // "text", "image", "document", "video", "audio", "location", "contact", "sticker", "gif
    };

    if (file.length !== 0) {
      const res = await handleUploadDocument();
      item = {
        ...item,
        messageType: fileType,
        attachmentPath: res?.data?.[0]?.s3URL,
      };
    }

    if (locationTmp) {
      item = {
        ...item,
        messageType: "location",
        message: `${locationTmp.latitude},${locationTmp.longitude}`,
      };
    }

    apiManager("POST", `chat/message?lang=${lang2}`, item)
      .then((res) => {
        if (res.status) {
          setSendLoading(false);
          getMessages(selectedChat);
        } else {
          setSendLoading(false);
          toast.error(res.message);
        }
      })
      .catch((err) => {
        setSendLoading(false);
        console.log(err);
        toast.error(err.message);
      });

    setMessage("");
    setFile([]);
    setRecordingUrl("");
    setLocation(null);
    setReplying(null);
    setSuggestion(false);
    const element = chatRef.current;
    element.scroll({
      top: chatData && chatData.length > 4 ? element.scrollHeight : 0,
      behavior: "smooth",
    });
  };

  const handleFiles = (files, type) => {
    setFile(files);
    setType(type);
    // if (files?.[0]?.type?.startsWith("image")) {
    //   setType("image");
    // } else if (files?.[0]?.type?.startsWith("video")) {
    //   setType("video");
    // } else if (files?.[0]?.type?.startsWith("audio")) {
    //   setType("audio");
    // } else {
    //   setType("document");
    // }
    handleClosePopover();
    setSuggestion(true);
  };

  const emptyFile = () => {
    setSuggestion(false);
    setFile([]);
    setRecordingUrl(null);
    setLocation(null);
  };

  const handleUploadDocument = async () => {
    const formData = new FormData();
    formData.append("files", Object.values(file)[0]);

    const apiCall = apiManager(
      "POST",
      `storage/upload?lang=${lang2}&type=${uploadType}&chatId=${selectedChat}`,
      formData,
      null,
      "multipart/form-data"
    )
      .then((res) => {
        if (res.status) {
          return res;
        } else {
          toast.error(res.message);
          return false;
        }
      })
      .catch((err) => {
        toast.error(err.message);
        return false;
      });
    return apiCall;
  };

  const handleStartRecording = () => {
    navigator.permissions
      .query({ name: "microphone" })
      .then(function (permissionStatus) {
        if (permissionStatus.state === "denied") {
          // granted, denied, prompt
          setModalType("microphone");
          setModal(true);
        } else {
          permissionStatus.onchange = function () {
            console.log("Permission changed to " + this.state);
          };
        }
      });

    recorder
      .start()
      .then(() => {
        // something else
        setIsRecording(true);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  console.log(file);
  const handleStopRecording = async () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        // do what ever you want with buffer and blob
        // Example: Create a mp3 file and play
        const file = new File(buffer, `${t('Recording')}`, {
          type: blob.type,
          lastModified: Date.now(),
        });
        setFile([file]);
        setIsRecording(false);
        setType("audio");
        // const player = new Audio(URL.createObjectURL(file));
        // player.play();
      })
      .catch((e) => {
        setIsRecording(false);
        console.log("error", e);
      });
  };

  let name =
    person?.sellerId === udata?.auth?.userId
      ? person?.buyerName
      : person?.buyerId === udata?.auth?.userId
      ? person?.sellerName
      : "--";

  const [location, setLocation] = useState(null);

  const getLocation = () => {
    setModalType("location");
    handleClosePopover();
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        console.log(pos);
        setLocation(pos.coords);
        sendLocation(pos.coords);
      },
      (err) => {
        setModal(true);
        console.log(err);
        setLocation({ error: true });
        toast.error("permission denied");
      }
    );
  };

  const sendLocation = (coords) => {
    handleSendMessage(null, coords);
    handleCloseModal();
  };

  // const handleScroll = async (e) => {
  //   const element = e.target;
  //   if (element.scrollTop == 0) {
  //     setPageNumber(pageNumber + 1);
  //     const res = await fetchMessages({
  //       chatId: selectedChat,
  //       currentPage: pageNumber,
  //     });
  //     setChatData([]);
  //     setChatData(res?.messages?.docs?.reverse());
  //     setPerson(res?.chat);
  //   }
  // };

  const [replyingMargin, setReplyingMargin] = useState("70px");
  useEffect(() => {
    if (!isRecording && file.length == 0 && !recordingUrl && !location) {
      setReplyingMargin("70px");
    } else {
      setReplyingMargin("150px");
    }
  }, [isRecording, file, recordingUrl, location]);
  const [attachmentPopover, setAttachmentPopover] = useState(false);
  const handleClosePopover = () => {
    setAttachmentPopover(false);
  };
  return (
    <div>
      {chatData ? (
        <div
          class="test test-right encloseBorder"
          style={{
            [`border${lang2 === "en" ? "Left" : "Right"}`]: "1px solid #ccc",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          <div class="obert">
            <div className="d-flex justify-content-between align-items-center fs-4 fw-semibold p-3">
              <div className="">{name}</div>
              <i
                class="fa-solid fa-circle float-end"
                style={{ color: "green" }}
              ></i>
              {/* <span className={`float-${lang2 === "en" ? "end" : "start"}`}>
                <img
                  src="../assets/images/location.png"
                  onClick={() => {
                    setModal(true);
                    getLocation();
                  }}
                  style={{ cursor: "pointer" }}
                  alt="img"
                  width={20}
                  className="mx-1"
                />
              </span> */}
            </div>

            {/* <span class="d-block d-sm-none">
            <p>{t("Manager")}</p>
            <div class="chatuser">
              <img src="../assets/images/user.jpg" align="user" />
            </div>
          </span> */}
          </div>
          <div
            class="innchat thinScroll"
            // ref={chatRef}
            // onScroll={handleScroll}
            style={{ minHeight: "53vh", overflowX: "hidden" }}
          >
            <ul
              className="d-flex flex-column"
              style={{ marginBottom: "60px", paddingInline: "10px" }}
            >
              {loading ? (
                <div
                  class="d-flex justify-content-center"
                  style={{ marginTop: "25vh" }}
                >
                  <div class="spinner-grow" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <li className="row">
                  {chatData?.map((item, key) => {
                    return (
                      <LeftRigtChat
                        chatRef={chatRef}
                        datas={item}
                        person={person}
                        classs={
                          item?.senderId === udata?.auth?.userId
                            ? "inright"
                            : "inleft"
                        }
                        // classs={
                        //   item?.recipientId === udata?.auth?.userId
                        //     ? lang2 === 'en' ? "inleft" : 'inright'
                        //     : lang2 === 'en' ? "inright" : 'inleft'
                        // }
                        key={key}
                        parentMessage={
                          chatData.filter(
                            ({ _id }) => _id == item?.messageParentId
                          )[0]
                        }
                        setReplying={setReplying}
                      />
                    );
                  })}
                  <div ref={chatRef}></div>
                </li>
              )}
            </ul>
          </div>
          {(isRecording || recordingUrl) && (
            <div className="msgshow">
              {isRecording ? (
                <BeatLoader
                  loading={isRecording}
                  className="form-control text-center"
                  color="#36d7b7"
                />
              ) : (
                <>
                  <div className="d-flex justify-content-">
                    <span
                      style={{
                        left: "20px",
                        right: "unset",
                      }}
                      className="d-flex"
                    >
                      <Icon icon="iconoir:sound-min" width={27} />
                    </span>
                  </div>
                  <img
                    src="../assets/images/close_img.png"
                    alt="icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => emptyFile()}
                  />
                </>
              )}
            </div>
          )}
          {file.length !== 0 ? (
            <>
              <div class="msgshow">
                {/* <h6>Replying to Robert</h6>
              <p>I've been busy with work as well.....</p> */}
                <div className="d-flex justify-content-">
                  {Object.values(file).map((v) => {
                    return (
                      <span
                        style={{
                          left: "20px",
                          right: "unset",
                        }}
                        className="d-flex"
                      >
                        <img
                          src={
                            v?.type?.startsWith("image")
                              ? URL.createObjectURL(v)
                              : v?.type?.startsWith("video")
                              ? "/assets/images/video.png"
                              : v?.type?.startsWith("audio")
                              ? "/assets/images/audio.png"
                              : "/assets/images/document.png"
                          }
                          // className="mx-4"
                          style={{
                            height: "40px",
                            borderRadius: "5px",
                            position: "unset",
                          }}
                        />
                        <p className="d-flex justify-content-center align-items-center mx-4">
                          {v?.name}
                        </p>
                      </span>
                    );
                  })}
                </div>
                <img
                  src="../assets/images/close_img.png"
                  alt="icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => emptyFile()}
                />
              </div>
            </>
          ) : // : location ? (
          //   <>
          //     <div class="msgshow">
          //       <div className=" d-flex justify-content-center ">
          //         <img
          //           style={{ position: "unset", height: "70px" }}
          //           src="/assets/images/location.png"
          //           className="img-fluid rounded-4"
          //           alt="location"
          //         />
          //       </div>
          //       <p className="d-flex justify-content-between">
          //         <p>{location?.latitude}</p>
          //         <p>{location?.longitude}</p>
          //       </p>
          //       <img
          //         src="../assets/images/close_img.png"
          //         alt="icon"
          //         style={{ cursor: "pointer" }}
          //         onClick={() => emptyFile()}
          //       />
          //     </div>
          //   </>
          // )
          null}
          {replying ? (
            <div
              className="d-flex justify-content-between bg-light rounded-4 p-2 border"
              style={{
                position: "absolute",
                bottom: replyingMargin,
                right: "20px",
              }}
            >
              <div
                className="text-end px-5 py-1"
                style={{ position: "relative" }}
              >
                <h6 className="mb-2">
                  {replying?.msg?.senderId === udata.auth.userId
                    ? t("Reply")
                    : `${t("Replying to")} ${name}`}
                </h6>
                <p>{replying?.data}</p>
                <img
                  src="../assets/images/close_img.png"
                  alt="icon"
                  height={20}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                  }}
                  onClick={() => setReplying(null)}
                />
              </div>
            </div>
          ) : null}
          <form class="intype" onSubmit={handleSendMessage}>
            <span>
              {/* <img
                src="../assets/images/paper-clip.png"
                style={{ cursor: "pointer" }}
                alt="img"
                className="mx-1"
              /> */}

              <Button
                id={`attachmentPopover`}
                type="button"
                className="btn-sm btn-light"
                style={{
                  backgroundColor: "transparent",
                  border: "unset",
                  padding: "0px",
                }}
                onClick={() => setAttachmentPopover(true)}
              >
                <img
                  src="../assets/images/paper-clip.png"
                  style={{ cursor: "pointer" }}
                  alt="img"
                  className="mx-1"
                />
              </Button>
              <Popover
                isOpen={attachmentPopover}
                placement="bottom"
                target={`attachmentPopover`}
                // trigger='legacy'
                toggle={handleClosePopover}
              >
                <PopoverBody>
                  <div className="row">
                    <div className="col-4 d-flex flex-column align-items-center mb-3">
                      <label
                        htmlFor="docInput"
                        className=" p-2 rounded-circle"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#ebebeb",
                        }}
                      >
                        <Icon
                          icon="ion:document"
                          height={30}
                          width={30}
                          style={{ color: "black" }}
                        />
                      </label>
                      <span>{t("Document")}</span>
                      <input
                        id={"docInput"}
                        className="d-none"
                        type="file"
                        accept=".doc,.docx,.xls,.xlsx,.pdf,.txt"
                        onClick={(e) => (e.target.value = null)}
                        disabled={location ? true : false}
                        onChange={(e) =>
                          handleFiles(e.target.files, "document")
                        }
                      />
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center mb-3">
                      <label
                        htmlFor="videoInput"
                        className=" p-2 rounded-circle"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#ebebeb",
                        }}
                      >
                        <Icon
                          icon="lets-icons:video-fill"
                          height={30}
                          width={30}
                          style={{ color: "black" }}
                        />
                      </label>
                      <span>{t("Video")}</span>
                      <input
                        id={"videoInput"}
                        className="d-none"
                        type="file"
                        accept=".mp4,.avi,.flv"
                        onClick={(e) => (e.target.value = null)}
                        disabled={location ? true : false}
                        onChange={(e) => handleFiles(e.target.files, "video")}
                      />
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center mb-3">
                      <label
                        htmlFor="imgInput"
                        className=" p-2 rounded-circle"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#ebebeb",
                        }}
                      >
                        <Icon
                          icon="mage:image-fill"
                          height={30}
                          width={30}
                          style={{ color: "black" }}
                        />
                      </label>
                      <span>{t("Picture")}</span>
                      <input
                        id={"imgInput"}
                        className="d-none"
                        type="file"
                        accept=".jpg,jpeg,.png"
                        onClick={(e) => (e.target.value = null)}
                        disabled={location ? true : false}
                        onChange={(e) => handleFiles(e.target.files, "image")}
                      />
                    </div>
                    <div className="col-4 d-flex flex-column  align-items-center mb-3">
                      <label
                        htmlFor="audioInput"
                        className=" p-2 rounded-circle"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#ebebeb",
                        }}
                      >
                        <Icon
                          icon="material-symbols-light:audio-file-rounded"
                          height={30}
                          width={30}
                          style={{ color: "black" }}
                        />
                      </label>
                      <span>{t("Audio")}</span>
                      <input
                        id={"audioInput"}
                        className="d-none"
                        type="file"
                        accept=".mp3,.wav,.aac,.ogg"
                        onClick={(e) => (e.target.value = null)}
                        disabled={location ? true : false}
                        onChange={(e) => handleFiles(e.target.files, "audio")}
                      />
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center mb-3">
                      <div
                        htmlFor="docInput"
                        className=" p-2 rounded-circle"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#ebebeb",
                        }}
                        onClick={(e) => {
                          getLocation();
                        }}
                      >
                        <Icon
                          icon="mingcute:location-fill"
                          height={30}
                          width={30}
                          style={{ color: "black" }}
                        />
                      </div>
                      <span>{t("Location")}</span>
                    </div>
                  </div>
                </PopoverBody>
              </Popover>
            </span>
            <input
              type="text"
              class="form-control"
              id="type"
              disabled={location ? true : false}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t("Type your message")}
            />
            {/* <span class="smile">
              <img src="../assets/images//emoji-happy.png" alt="img" />
            </span> */}

            {!sendLoading ? (
              <>
                {message?.length === 0 &&
                file?.length === 0 &&
                !recordingUrl &&
                !location ? (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      return isRecording
                        ? handleStopRecording()
                        : handleStartRecording();
                    }}
                    style={{
                      transform: "unset",
                      [lang2 === "en" ? "right" : "left"]: "9px",
                    }}
                  >
                    <Icon
                      icon={
                        isRecording
                          ? "material-symbols:stop"
                          : "material-symbols:mic"
                      }
                      color="#0d6efd"
                      height={27}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    style={{ [lang2 === "en" ? "right" : "left"]: "9px" }}
                  >
                    <img src="../assets/images//paper-airplane.png" alt="img" />
                  </button>
                )}
              </>
            ) : (
              <button
                type="submit"
                disabled
                style={{
                  cursor: "default",
                  [lang2 === "en" ? "right" : "left"]: "9px",
                }}
              >
                <div class="spinner-grow" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            )}
          </form>
        </div>
      ) : (
        <h4
          style={{ height: "60vh" }}
          className="d-flex justify-centent-center align-items-center"
        >
          {/* {t("No records available")} */}
        </h4>
      )}
      <Modal size="md" isOpen={modal} centered toggle={handleCloseModal}>
        {/* <ModalHeader>
        </ModalHeader> */}
        <ModalBody>
          {modalType === "location" && (
            <>
              {location?.error ? (
                <>
                  <h5 className="my-2">{t("Location permission is denied")}</h5>
                </>
              ) : (
                <>
                  <h5 className="my-2">{t("Send your current location")}?</h5>
                </>
              )}
            </>
          )}

          {modalType === "microphone" && (
            <>
              <h5>{t("Microphone permission is denied")}</h5>
            </>
          )}

          <div className={`d-flex justify-content-end`}>
            <button
              style={{ height: "45px" }}
              className="btn btn-light w-25"
              onClick={() => handleCloseModal()}
            >
              {t("Close")}
            </button>
            {/* {!location?.error && (
              <button
                className="w-25 themeButton"
                onClick={() => sendLocation()}
              >
                {t("Yes")}
              </button>
            )} */}
          </div>
        </ModalBody>
        {/* <ModalFooter>
        </ModalFooter> */}
      </Modal>
    </div>
  );
}
