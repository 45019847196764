import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//  import { homeOptions } from '../../../data/content';

import OwlCarousel from "react-owl-carousel-rtl";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { homeOptions } from "../../../../../data/content";
import { apiManager } from "../../../../../api/connectAPI";
import { useSelector } from "react-redux";
import { mostViewed } from "../../../../../Reducer/api";
import {
  beginWithImage,
  formatPrice,
  makeTagText,
} from "../../../../../utils/constants";
import { useNavigate } from "react-router-dom";

export default function ViewedCar({ data }) {
  const navigate = useNavigate();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const { t, i18n } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const length = Object.keys(data).length;
  const maxItemsToShow = 6;
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  const styles = {
    display: "block",
  };

  const gotoSingleCar = (item) => {
    if (item?.adTypeName?.["en"] === "Sell") {
      navigate(`/SingleCar?adId=${item?._id}`);
    } else {
      navigate(`/SingleRental?adId=${item?._id}`);
    }
  };
  console.log(data);
  return (
    <div className="">
      <div className=" d-none d-md-block">
        <div className="row">
          {length > 0 &&
            data
              .slice(0, showAll ? data.length : maxItemsToShow)
              .map((item, key) => {
                return (
                  <div className="col-sm-3" key={key}>
                    <div
                      className="set"
                      onClick={() => gotoSingleCar(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <h6>
                        <img src="../assets/images/eye.png" alt="eye" />
                        {item.totalViews}
                      </h6>
                      <div className="img">
                        <img
                          src={
                            beginWithImage(item?.uploads)?.[0] ||
                            "assets/images/notFound.png"
                          }
                          alt="blog1"
                          className="w-100"
                        />
                      </div>
                      <h4>{formatPrice(lang, item.askPrice)}</h4>
                      <h3>
                        {t(item?.properties?.basicDetails?.make?.[lang])}{" "}
                        {t(item?.properties?.basicDetails?.model?.[lang])}
                      </h3>
                      <p
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {makeTagText(item, lang)}
                      </p>
                    </div>
                  </div>
                );
              })}

          {length > maxItemsToShow && (
            <p className="showless d-none d-md-block" onClick={toggleShowAll}>
              {t(showAll ? "Show less" : "Show more")}
              <br />{" "}
              {showAll ? (
                <i className="fa-solid fa-chevron-up"></i>
              ) : (
                <i className="fa-solid fa-chevron-down"></i>
              )}
            </p>
          )}
        </div>
      </div>
      <div className="d-block d-md-none">
        {data.length > 0 ? (
          <OwlCarousel
            className="owl-carousel Recently_carousel owl.carousel.min owl-theme"
            style={styles}
            {...homeOptions}
            // margin={10}
            rtl={lang === "en" ? false : true}
            // nav
          >
            {data.length > 0 &&
              data.map((item, key) => {
                return (
                  <div className="item" key={key}>
                    <div
                      className="set"
                      onClick={() => gotoSingleCar(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <h6 style={{ direction: "ltr !important" }}>
                        <span>
                          <img
                            className=""
                            src="../assets/images/eye.png"
                            alt="eye"
                          />
                        </span>
                        <span>{item.totalViews}</span>
                      </h6>
                      <div className="img">
                        <img
                          src={
                            beginWithImage(item?.uploads)?.[0] ||
                            "assets/images/notFound.png"
                          }
                          alt="blog1"
                          className="w-100"
                        />
                      </div>
                      <h4 style={{ fontSize: "18px" }}>
                        {formatPrice(lang, item.askPrice)}
                      </h4>
                      <h3 style={{ fontSize: "15px" }}>
                        {t(item?.properties?.basicDetails?.make?.[lang])}{" "}
                        {t(item?.properties?.basicDetails?.model?.[lang])}
                      </h3>
                      <p
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {makeTagText(item, lang)}
                      </p>
                    </div>
                  </div>
                );
              })}
          </OwlCarousel>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
