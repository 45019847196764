import React from "react";
import Options from "./options";
import { useSelector } from "react-redux";
import { t } from "i18next";
const SelectForm = ({
  style,
  selectedOption,
  values,
  event,
  name,
  onChange,
  disabled,
}) => {
  const lang1 = useSelector((state) => state.auth.language);
  const lang2 = lang1 === "English" ? "en" : lang1 === "Arabic" ? "ar" : "ku";
  // console.log(values, name)
  return (
    <select
      defaultValue={null}
      name={name}
      onChange={onChange}
      {...event(name)}
      className={style}
      disabled={disabled}
    >
      <Options
        value={t("Select")}
        disabled
        style={""}
        hidden={true}
        selected={!selectedOption || selectedOption?.length === 0}
      ></Options>
      {values?.map((item) => {
        const Name =
          item?.name?.[lang2] ||
          item?.name?.[lang2] ||
          item?.name ||
          item?.label
          // console.log(Name)
        return (
          <Options
            key={item?.id}
            value={t(Name)}
            selected={
              typeof selectedOption === "string" && typeof Name === "string"
                ? selectedOption?.toLowerCase() === Name?.toLowerCase()
                : selectedOption === Name
            }
            // inlineStyles={
            //   item?.hex
            //     ? {
            //         backgroundColor: item?.hex,
            //         color: [
            //           "Black",
            //           "Blue",
            //           "Dark Blue",
            //           "Maroon",
            //           "Brown",
            //         ].includes(item?.name?.["en"])
            //           ? "white"
            //           : "black",
            //         backgroundSize: "300px 100px",
            //       }
            //     : null
            // }
          ></Options>
        );
      })}
    </select>
  );
};
export default SelectForm;
