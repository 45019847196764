import React, { useEffect } from "react";
import AboutAko from "../../MyComponents/AboutAko";

export default function AboutPage() {

  
  return (
    <div className="container my-5">
      <AboutAko />
    </div>
  );
}
