import React, { useEffect,useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import NewcarForm from '../NewCars/NewcarForm' 
import { Newcars } from '../../../Reducer/formSlice';
import { toast } from 'react-toastify';
export default function NewcarBanner() {
  const { t, i18n } = useTranslation();
  const detail = useSelector(state => state.fromsdata.newcarsdata);
  const loading = useSelector(state => state.fromsdata.loading);
  const error = useSelector(state => state.fromsdata.error);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Newcars());
  }, [dispatch]);
  
  if(error)
  {
    toast.error(error);
  }
  
  return (<section class="banner hbanner " style={{backgroundImage: `url(${detail.electric_cars ? detail.electric_cars : '/assets/images/newcar.jpg' })`}}>
  <div class="container">
      <div class="row">
          <div class="col-md-6">
              <div class="setform electricbanner">
                  <h6>{t('Electric cars')}</h6>
                  <h5>{t('The future of driving')}</h5>
                  <div class="tab-content" id="myTabContent">
                    <NewcarForm data={detail}/>
                  </div>
              </div>
          </div>
          <div class="col-md-6">
          </div>
      </div>
  </div>
</section>);
}
