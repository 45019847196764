import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { NavLink, useNavigate } from "react-router-dom";
import FormInput from "../../../../Tools/FormInput";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { apiManager } from "../../../../../api/connectAPI";
import { useSelector } from "react-redux";
export default function EditSecurity() {
  const location = useLocation();
  const navigate = useNavigate();
  // const meta=location.state.data;
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    language === "English" ? "en" : language === "Arabic" ? "ar" : "ku";
  const [submitting, setSubmitting] = useState(false);
  const schema = Yup.object().shape({
    currentpassword: Yup.string().required(t("Current Password is required")),
    newpassword: Yup.string().required(t("New Password is required")),
    confirmPassword: Yup.string()
      .required(t("Confirm Password is required"))
      .test(
        "matchPassword",
        t("Confirm password does not match"),
        (v) => v === getValues("newpassword")
      ),
  });
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onError = (errors) => {
    console.log(errors);
  };

  const onSubmitform = async (data) => {
    // alert('test');
    console.log(data);
    if (submitting == false) {
      setSubmitting(true);
      //  let formData=new FormData();
      //  formData.append('currentpassword',data.currentpassword);
      //  formData.append('newpassword',data.newpassword);
      //  formData.append('confirmPassword',data.confirmPassword);
      //  formData.append('language',i18n.language);
      apiManager("PUT", `user/changePassword?lang=${lang}`, {
        oldPassword: data.currentpassword,
        newPassword: data.newpassword,
      })
        .then((res) => {
          if (res.status) {
            navigate("/MyAccount/MyProfile");
            // toast.success(res.message);
            reset();
            setSubmitting(false);
          } else {
            console.log(res);
            setError("newpassword", { message: res?.message });
            toast.error(res.message);
            setSubmitting(false);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.message);
          setSubmitting(false);
        });
    }
  };

  return (
    <div>
      <div class="my_profile login" style={{ position: "relative" }}>
        <form onSubmit={handleSubmit(onSubmitform, onError)}>
          <h3>
            {t("Security")}{" "}
            <span class="d-none d-sm-block">
              <NavLink to="/MyAccount/MyProfile">{t("Cancel")}</NavLink>
              <button class="save" disabled={submitting} type="submit">
                {t("Save")}
              </button>
            </span>
          </h3>
          <div class="row">
            <div class="col-sm-6 col-xl-3 form-group psd">
              <h6>{t("Current Password")}</h6>
              <span class="password d-block">
                <FormInput
                  style={"form-control"}
                  intype={"password"}
                  event={register}
                  value=""
                  name={"currentpassword"}
                  placeholder={t("Current Password")}
                />
                {errors.currentpassword && (
                  <p
                    className={`helperText w-100 ${
                      lang === "en" ? "text-start" : "text-end"
                    }`}
                  >
                    {t(errors.currentpassword.message)}
                  </p>
                )}
              </span>
            </div>
            <div class="col-sm-6 col-xl-3  form-group psd">
              <h6>{t("New Password")}</h6>
              <span class="password d-block">
                <FormInput
                  style={"form-control"}
                  intype={"password"}
                  event={register}
                  value=""
                  name={"newpassword"}
                  placeholder={t("New Password")}
                />
                {errors.newpassword && (
                  <p
                    className={`helperText w-100 ${
                      lang === "en" ? "text-start" : "text-end"
                    }`}
                  >
                    {t(errors.newpassword.message)}
                  </p>
                )}
              </span>
            </div>
            <div class="col-sm-6 col-xl-3  form-group psd">
              <h6>{t("Confirm Password")}</h6>
              <span class="password d-block">
                <FormInput
                  style={"form-control"}
                  intype={"password"}
                  event={register}
                  value=""
                  name={"confirmPassword"}
                  placeholder={t("Confirm Password")}
                />
                {errors.confirmPassword && (
                  <p
                    className={`helperText text-nowrap w-100 ${
                      lang === "en" ? "text-start" : "text-end"
                    }`}
                  >
                    {t(errors.confirmPassword.message)}
                  </p>
                )}
              </span>
            </div>
            {/* <div class="col-12 d-block d-sm-none albtn"> 
              <a class="save w-100" href="#">Save</a><a class="w-100 mb-3" href="#">Cancel</a>
            </div> */}
            <span class="d-block d-sm-none">
              <NavLink
                className="my-2 btn btn-outlined-primary w-100"
                style={{ border: "1px solid #4C7EE8" }}
                to="/MyAccount/MyProfile"
              >
                {t("Cancel")}
              </NavLink>
              <button
                class="save my-2 btn w-100 btn-primary"
                disabled={submitting}
                type="submit"
              >
                {submitting ? t("Please wait...") : t("Save")}
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}
