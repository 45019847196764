import React, { useEffect, useState } from "react";
import ListItem from "../ListItem";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { Icon } from "@iconify/react";
export default function Checkbox({
  title,
  data,
  id,
  type,
  allFilters,
  setAllFilters,
  setSelected,
}) {
  const { t, i18n } = useTranslation();
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const keyName = (type) => {
    switch (type) {
      case "body":
        return "condition";
      case "paint":
        return "paint";
      case "colour":
        return "colour";
      case "regional_space":
        return "regional";
      case "gearbox":
        return "gearbox";
      case "fuel":
        return "fuel";
      case "body_type":
        return "bodyType";
    }
  };

  const handleChange = (e) => {
    const value = e.target.name;
    console.log(value)
    if (value === t("Any")) return;
    const key = keyName(type);
    console.log(key)
    if (e.target.checked) {
      setAllFilters({ ...allFilters, [key]: [...allFilters?.[key], value] });
    } else {
      setAllFilters({
        ...allFilters,
        [key]: allFilters?.[key]?.filter((v) => v !== value),
      });
    }
  };

  const setTitle = () => {
    switch (type) {
      case "body":
        return t("Select Body condition");
      case "paint":
        return t("Select Paint");
      case "colour":
        return t("Select Colour");
      case "regional_space":
        return t("Select Regional specs");
      case "gearbox":
        return t("Select Gearbox");
      case "fuel":
        return t("Select Fuel type");
      case "body_type":
        return t("Select Body Type");
    }
  };

  const clearFilter = (type) => {
    const key = keyName(type);
    setAllFilters({...allFilters, [key]: []})
  };

  return (
    <ul class="dropdown-menu drp" id={id}>
      <h5>
        {setTitle()}
        <a
          onClick={() => {
            $(".drp").removeClass("show");
            $(".dropdown").removeClass("show");
            setSelected(null);
          }}
        >
          <img src="assets/images/close_img.png" alt="icon" />
        </a>
      </h5>
      <div class="setp setp3">
        <ul>
          <ListItem
            innerhtmlType={"html"}
            value={
              <>
                <input
                  type="checkbox"
                  onChange={handleChange}
                  name={t("Any")}
                  disabled
                  // value={item?.}
                  checked={allFilters?.[keyName(type)]?.length == 0}
                />
                {t("Any")}
              </>
            }
          />
        </ul>
      </div>
      <div class="setp setp3 d-flex flex-column">
        <ul>
          {data && data.length > 0
            ? data.map((item, index) => {
                return (
                  <ListItem
                    innerhtmlType={"html"}
                    value={
                      <>
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          name={item?.name?.en || item?.name?.English}
                          // value={item?.}
                          checked={allFilters?.[keyName(type)]?.includes(
                            item?.name?.en
                          )}
                        />
                        {t(item?.name?.[i18n.language] || item?.name?.[lang])}
                      </>
                    }
                  />
                );
              })
            : ""}
        </ul>
      <p
        style={{ cursor: "pointer" }}
        className={`d-flex justify-content-start`}
        onClick={() => {
          if (clearFilter) {
            clearFilter(type);
          }
        }}
      >
        {/* <Icon icon="icon-park-solid:clear" /> Clear */}
        <button
          className="btn-hover-clear rounded-1 px-2 py-1"
          style={{
            backgroundColor: "white",
            border: "1px solid #4C7EE8",
            color: "#4C7EE8",
          }}
        >
          {t("Clear")}
        </button>
      </p>
      </div>
    </ul>
  );
}
