import React from "react";
import RecentlyCarView from "../../../Tools/RecentCarView";
import { t } from "i18next";

export default function Recent({ rtl }) {
  return (
    <>
      <h2 class="hed">{t("Recently viewed car deals")}</h2>
      <RecentlyCarView rtl={rtl} />
    </>
  );
}
