import withoutVin from "../data/withoutVin.json";
import filter from "../data/filter.json";
import { getAdFiltersList, getMake } from "../Reducer/api";
import { t } from "i18next";
import moment from "moment";
import { fromAddress } from "react-geocode";
import { apiManager } from "../api/connectAPI";

export const DEFAULT_LOCATION = {
  //baghdad latitude and longitude
  latitude: "33.312805",
  longitude: "44.361488",
};

export const exPh = ["918874227666", "919818140563", "442080640337"]

export const itemLimit = 24;

export const highlightColor = "#F8EFC3";

export const timeData12 = [
  "00:00 AM",
  "00:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

export const timeData24 = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const formatTime12to24 = (time) => {
  const i = timeData12.indexOf(time);
  return timeData24[i];
};
export const formatTime24to12 = (time) => {
  const i = timeData24.indexOf(time);
  return timeData12[i];
};

export function convertTime(time, format) {
  if (time.toLowerCase().includes("am") || time.toLowerCase().includes("pm")) {
    format = "24-hour";
  } else {
    format = "12-hour";
  }
  if (format === "24-hour") {
    // Convert from 12-hour to 24-hour format
    let [timePart, modifier] = time.split(" ");
    modifier = modifier.toUpperCase();
    let [hours, minutes] = timePart.split(":");
    hours = parseInt(hours, 10);
    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }
    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  } else if (format === "12-hour") {
    // Convert from 24-hour to 12-hour format
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    let modifier = "AM";
    if (hours >= 12) {
      modifier = "PM";
    }
    if (hours > 12) {
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }
    return `${hours.toString().padStart(2, "0")}:${minutes} ${modifier}`;
  } else {
    throw new Error('Invalid format specified. Use "12-hour" or "24-hour".');
  }
}

export function removeAmPm(timeString) {
  // Regular expression to match "am" or "pm" in the time string
  var regex = /\b(?:am|pm)\b/gi;
  // Replace "am" or "pm" with an empty string
  return `${timeString.replace(regex, "")}${
    timeString.toLowerCase().includes("pm")
      ? " " + t("PM") + " "
      : " " + t("AM") + " "
  }`;
}

export const staticWorkingHourData = [
  {
    index: 0,
    day: "Sunday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 1,
    day: "Monday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 2,
    day: "Tuesday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 3,
    day: "Wednesday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 4,
    day: "Thursday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 5,
    day: "Friday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
  {
    index: 6,
    day: "Saturday",
    startTime: "00:00 am",
    endTime: "00:00 am",
    active: false,
  },
];

export const showTimeUnixTranslated = (time) => {
  const tm = moment.unix(time);
  return `${tm.format("hh:mm")} ${t(tm.format("a").toUpperCase())}`;
};

export function camelToNormal(inputString) {
  if (!inputString) return "";
  // Use a regular expression to add spaces before uppercase letters
  const readableString = inputString.replace(/([A-Z])/g, " $1");

  // Convert the first character to uppercase and remove leading space if any
  return (
    readableString.charAt(0).toUpperCase() + readableString.slice(1).trim()
  );
}
export const handleDownload = (url) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = true;
  a.target = "_blank";
  a.click();
};
export const getFeatureName = (name) => {
  const objBasic = withoutVin.data.basicDetails.filter(
    (v) => v.value === name
  )[0];
  const objSpeci = withoutVin.data.specifications.filter(
    (v) => v.value === name
  )[0];
  const objFeatures = withoutVin.data.features.filter(
    (v) => v.value === name
  )[0];
  return objBasic?.name || objSpeci?.name || objFeatures?.name || null;
};

export function getYearsFrom1950ToCurrent() {
  const currentYear = new Date().getFullYear();
  const startYear = 1950;

  const years = [];
  let i = 1;
  for (let year = currentYear + 1; year >= startYear; year--, i++) {
    years.push({ id: i, name: { en: year, ar: year, ku: year }, value: year });
  }
  return years;
}

export const getAllFiltersData = async (page, ownerId, allFilters) => {
  let filters = withoutVin.data;
  let moreFilters = filter.data.sideBar;

  // const entityTypes = await getEntityTypes();
  // makeData = await getMake({isLive: true, limit: 200, ownerId: ownerId, page: page, filters: allFilters})
  // allFiltersList = await getAdFiltersList(page, ownerId, allFilters);
  let makeData = null;
  let allFiltersList = null;
  const allApiData = await Promise.all([
    getMake({
      isLive: true,
      limit: 200,
      ownerId: ownerId,
      page: page,
      filters: allFilters,
    }).then((res) => (makeData = res)),
    getAdFiltersList(page, ownerId, allFilters).then(
      (res) => (allFiltersList = res)
    ),
    apiManager("GET", `admin/getAllYears?lang=en&limit=200`)
  ]);
  makeData = allApiData[0];
  allFiltersList = allApiData[1];
  moreFilters = {
    ...moreFilters,
    min_max_year: {
      min: {
        ...moreFilters.min_max_year.min,
        data: allApiData[2]?.data?.years,
      },
      max: {
        ...moreFilters.min_max_year.max,
        data: [
          {
            id: 1,
            name: {
              en: "Brand new",
              ar: "Brand new",
              ku: "Brand new",
            },
            value: 9999,
          },
          ...allApiData[2]?.data?.years,
        ],
        dataRental: allApiData[2]?.data?.years,
      },
    },
    regional_space: allFiltersList?.regional,
    body: allFiltersList?.body,
    paint: allFiltersList?.paint,
    gearbox: allFiltersList?.transmission,
    colour: allFiltersList?.colour,
    fuel: allFiltersList?.fuel,
    cities: allFiltersList?.cities,
    body_type: allFiltersList.bodyType.map((v, i) => ({ ...v, id: i + 1 })),
    min_max_engine: {
      min: { ...moreFilters.min_max_engine.min, data: filters.size },
      max: { ...moreFilters.min_max_engine.max, data: filters.size },
    },
    min_max_engine_power: {
      min: { ...moreFilters.min_max_engine_power.min, data: filters.power },
      max: { ...moreFilters.min_max_engine_power.max, data: filters.power },
    },
    cylinders: {
      min: { ...moreFilters.cylinders.min, data: filters.cylinder },
      max: { ...moreFilters.cylinders.max, data: filters.cylinder },
    },
    doors: {
      min: { ...moreFilters.doors.min, data: withoutVin.data.doors },
      max: { ...moreFilters.doors.max, data: withoutVin.data.doors },
    },
    seats: {
      min: { ...moreFilters.seats.min, data: withoutVin.data.seats },
      max: { ...moreFilters.seats.max, data: withoutVin.data.seats },
    },
    make: [
      {
        name: t("Any"),
        value: "Any",
      },
      ...makeData?.makes,
    ],
    // model: modelData?.map((v) => ({ label: v, value: v })),
  };

  filters = { ...filters, ...moreFilters };
  // filters = { ...filters, cities: cities };

  console.log(filters);
  return filters;
};

export function checkNullUndefineReturnEmpty(value, returnType) {
  if (value) {
    if (value !== "N/A" && value !== "") {
      if (returnType === "text") return value;
      if (returnType === "boolean") return "true";
    }
  }

  if (returnType === "text") return value;
  if (returnType === "boolean") return false;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatPrice(lang, price) {
  if (isNaN(price)) price = 0;
  if (parseInt(price) === NaN) return t("Any");

  const fprice =
    lang === "en"
      ? `${t("IQD")} ${numberWithCommas(price)}`
      : `${numberWithCommas(price)} ${t("IQD")}`;

  return fprice;
}

export const getNameFromVal = (obj, val, lang) => {
  return withoutVin?.data?.[obj]?.filter((v) => v.value == val)?.[0]?.name?.[
    lang
  ];
};

export const makeTagText = (data, lang) => {
  const tagText = `${
    data?.properties?.basicDetails?.makeYear
      ? t(data?.properties?.basicDetails?.makeYear) + " | "
      : ""
  }`
    .concat(
      `${
        data?.properties?.specifications?.bodyType?.[lang]
          ? t(data?.properties?.specifications?.bodyType?.[lang]) + " | "
          : ""
      }`
    )
    .concat(
      `${
        data?.properties?.specifications?.km
          ? numberWithCommas(data?.properties?.specifications?.km) + " " + t("KMs") + " | "
          : ""
      }`
    )
    .concat(
      `${
        data?.properties?.specifications?.engineSize
          ? t(
              getNameFromVal(
                "size",
                data?.properties?.specifications?.engineSize,
                lang
              )
            ) + " | "
          : ""
      }`
    )
    .concat(
      `${
        data?.properties?.specifications?.enginePower
          ? `${numberWithCommas(data?.properties?.specifications?.enginePower)} ${t("bhb")}` + " | "
          : ""
      }`
    )
    .concat(
      `${
        data?.properties?.specifications?.gearbox?.[lang]
          ? t(data?.properties?.specifications?.gearbox?.[lang]) + " | "
          : ""
      }`
    )
    .concat(
      `${
        data?.properties?.specifications?.fuelType?.[lang]
          ? t(data?.properties?.specifications?.fuelType?.[lang])
          : ""
      }`
    );
  return tagText;
};

export function isImageOrVideo(url) {
  // Extract the file extension from the URL
  const extension = url?.split?.(".").pop().toLowerCase();

  // Check if the extension corresponds to an image or video format
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
  const videoExtensions = ["mp4", "avi", "mkv", "mov", "webm"];

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "unknown";
  }
}

const filterSkip = [t("Any"), "$0", t("Brand new")];
export const showFilteredValues = (type, fullData, value, minOrMax) => {
  // console.log(value)
  if (!value) return fullData;
  if (filterSkip.includes(value)) return fullData;

  if (minOrMax === "min") {
    return fullData?.filter((v) => v.value < parseFloat(value));
  }
  if (minOrMax === "max") {
    return fullData?.filter((v) => v.value > parseFloat(value));
  }

  return fullData;
};

export const userActivityLocal = (id) => {
  let saved = localStorage.getItem("viewedAds");
  console.log(saved);
  if (saved?.includes(id)) {
    delete saved[saved?.indexOf(id)];
  }
  saved = [id, ...saved];
  localStorage.setItem("viewedAds", saved);
};

export function beginWithImage(urls) {
  if (!urls || urls?.length == 0) {
    return urls;
  }
  urls = urls.map((v) => v.s3URL);
  const isImageUrl = (url) => {
    if (!url) return false;
    // You can implement additional logic here to check if the URL is an image
    // For simplicity, let's assume it's an image if it ends with common image file extensions
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp"];
    return imageExtensions.some((ext) => url?.toLowerCase()?.endsWith(ext));
  };

  const isFirstElementImage = isImageUrl(urls[0]);

  if (isFirstElementImage) {
    return urls;
  } else {
    const indexOfImage = urls.findIndex(isImageUrl);

    if (indexOfImage !== -1) {
      // Swap the first element with the image URL
      [urls[0], urls[indexOfImage]] = [urls[indexOfImage], urls[0]];
    }

    return urls;
  }
}

export const createFiltersString = (filters) => {
  let str = "";
  if (filters?.make && filters?.make !== t("Any") && filters?.make !== "All") {
    str = str.concat(`&makeNames=${filters?.make}`);
  }
  if (
    filters?.model &&
    filters?.model !== t("Any") &&
    filters?.model !== "All"
  ) {
    str = str.concat(`&model=${filters?.model}`);
  }
  if (
    filters?.city?.length > 0 &&
    !filters?.city.includes(t("National")) &&
    !filters?.city.includes("National")
  ) {
    str = str.concat(`&city=${filters?.city?.toString()}`);
  }
  if (
    filters?.regional?.length > 0 &&
    !filters?.regional?.includes(t("Any")) &&
    !filters?.regional?.includes("All")
  ) {
    str = str.concat(`&regionalSpecs=${filters?.regional?.toString()}`);
  }
  if (
    filters?.condition?.length > 0 &&
    !filters?.condition?.includes(t("Any"))
  ) {
    str = str.concat(`&conditions=${filters?.condition?.toString()}`);
  }
  if (filters?.paint?.length > 0 && !filters?.paint?.includes(t("Any"))) {
    str = str.concat(`&paints=${filters?.paint?.toString()}`);
  }
  if (filters?.colour?.length > 0) {
    str = str.concat(`&colors=${filters?.colour?.toString()}`);
  }
  if (filters?.gearbox?.length > 0 && !filters?.gearbox?.includes(t("Any"))) {
    str = str.concat(`&gearboxs=${filters?.gearbox?.toString()}`);
  }
  if (filters?.fuel?.length > 0) {
    str = str.concat(`&fuelTypes=${filters?.fuel?.toString()}`);
  }
  if (filters?.bodyType?.length > 0 && !filters?.bodyType?.includes(t("Any"))) {
    str = str.concat(`&bodyTypes=${filters?.bodyType?.toString()}`);
  }
  if (filters?.price) {
    let rt = "";
    switch (filters?.rentType) {
      case "weekly":
        rt = "1";
        break;
      case "monthly":
        rt = "2";
        break;
    }
    if (filters?.price?.min !== t("Any")) {
      if (filters?.price?.min === "$0") {
        str = str.concat(`&minAskPrice${rt}=${"0"}`);
      } else {
        str = str.concat(`&minAskPrice${rt}=${filters?.price?.min}`);
      }
    }
    if (filters?.price?.max !== t("Any")) {
      str = str.concat(`&maxAskPrice${rt}=${filters?.price?.max}`);
    }
  }
  if (filters?.year) {
    if (filters?.year?.min !== t("Any")) {
      str = str.concat(`&minMakeYear=${filters?.year?.min}`);
    }
    if (filters?.year?.max !== t("Any")) {
      str = str.concat(`&maxMakeYear=${filters?.year?.max}`);
    }
  }
  if (filters?.km) {
    if (filters?.km?.min !== t("Any")) {
      str = str.concat(`&minKm=${filters?.km?.min}`);
    }
    if (filters?.km?.max !== t("Any")) {
      str = str.concat(`&maxKm=${filters?.km?.max}`);
    }
  }
  if (filters?.size) {
    if (filters?.size?.min !== t("Any")) {
      str = str.concat(`&minEngineSize=${filters?.size?.min}`);
    }
    if (filters?.size?.max !== t("Any")) {
      str = str.concat(`&maxEngineSize=${filters?.size?.max}`);
    }
  }
  if (filters?.power) {
    if (filters?.power?.min !== t("Any")) {
      str = str.concat(`&minEnginePower=${filters?.power?.min}`);
    }
    if (filters?.power?.max !== t("Any")) {
      str = str.concat(`&maxEnginePower=${filters?.power?.max}`);
    }
  }
  if (filters?.cylinders) {
    if (filters?.cylinders?.min !== t("Any")) {
      str = str.concat(`&minCylinders=${filters?.cylinders?.min}`);
    }
    if (filters?.cylinders?.max !== t("Any")) {
      str = str.concat(`&maxCylinders=${filters?.cylinders?.max}`);
    }
  }
  if (filters?.doors) {
    if (filters?.doors?.min !== t("Any")) {
      str = str.concat(`&minDoors=${filters?.doors?.min}`);
    }
    if (filters?.doors?.max !== t("Any")) {
      str = str.concat(`&maxDoors=${filters?.doors?.max}`);
    }
  }
  if (filters?.seats) {
    if (filters?.seats?.min !== t("Any")) {
      str = str.concat(`&minSeats=${filters?.seats?.min}`);
    }
    if (filters?.seats?.max !== t("Any")) {
      str = str.concat(`&maxSeats=${filters?.seats?.max}`);
    }
  }
  if (filters?.brandNew) {
    str = str.concat(`&brandNew=Yes`);
  }
  if (filters?.price?.finance) {
    str = str.concat(`&finance=Yes`);
  }
  return str;
};

export const numWithZero = (num) => {
  return parseFloat(num).toLocaleString("en", {
    useGrouping: false,
    minimumFractionDigits: 1,
  });
};
export const numWithZeroDec = (num, t) => {
  return parseFloat(num).toLocaleString("en", {
    useGrouping: false,
    minimumFractionDigits: t,
  });
};

export const openAddressOnMap = (address) => {
  if (!address) return;
  fromAddress(address)
    .then(({ results }) => {
      const { lat, lng } = results[0].geometry.location;
      console.log(lat, lng);
      window.open("https://maps.google.com?q=" + lat + "," + lng);
    })
    .catch(console.error);
};

export const twoDig = (num) => {
  console.log(num);
  return num.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export function convertDigitsToEnglish(text) {
  // Define a mapping of Arabic/Kurdish digits to English digits
  const digitMap = {
    "٠": "0",
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
  };

  const regex = /[٠١٢٣٤٥٦٧٨٩]/g;

  const convertedText = text.replace(
    regex,
    (match) => digitMap[match] || match
  );

  return convertedText;
}

// ٨٥٢٨٥٢٨٥٢٨

export const beforeRefresh = () => {
  console.log("byeeeeeeeeeeeeeee");
  localStorage.removeItem("adTypeData");
  localStorage.removeItem("entityTypeData");
};
