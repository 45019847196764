import React from "react";
import { useTranslation } from "react-i18next";
import SelectOptions from "../SelctOptions";
import ListItem from "../ListItem";
import ReactSelect from "react-select";
import ReactSelectCustom from "../ReactSelectCustom";
const SortBy = ({
  data,
  selectFilter,
  selectedContent,
  setSelectedContent,
  ownerId,
  previous,
  next,
  sortVal,
  setSortVal,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <h6 class="SortBy">
      <span class="aro">
        <a onClick={previous}>
          <i class="fa-solid fa-angle-left"></i>
        </a>{" "}
        <a onClick={next}>
          <i class="fa-solid fa-angle-right"></i>
        </a>
      </span>
      {ownerId && (
        <span className="login">
          <ul class="nav nav-tabs navTabsSortBy" id="myTab" role="tablist">
            {i18n.language === "English" ? (
              <>
                <ListItem
                  innerhtmlType={"button"}
                  key={0}
                  btnType={"button"}
                  onClick={() => setSelectedContent("normal")}
                  style={"nav-item"}
                  buttonStyle={
                    selectedContent == "normal" ? "nav-link active" : "nav-link"
                  }
                  buttonTitle={t("cars")}
                ></ListItem>
                <ListItem
                  innerhtmlType={"button"}
                  key={1}
                  btnType={"button"}
                  onClick={() => setSelectedContent("rental")}
                  style={"nav-item"}
                  buttonStyle={
                    selectedContent == "rental" ? "nav-link active" : "nav-link"
                  }
                  buttonTitle={t("rentals")}
                ></ListItem>
              </>
            ) : (
              <>
                <ListItem
                  innerhtmlType={"button"}
                  key={1}
                  btnType={"button"}
                  onClick={() => setSelectedContent("rental")}
                  style={"nav-item"}
                  buttonStyle={
                    selectedContent == "rental" ? "nav-link active" : "nav-link"
                  }
                  buttonTitle={t("rentals")}
                ></ListItem>
                <ListItem
                  innerhtmlType={"button"}
                  key={0}
                  btnType={"button"}
                  onClick={() => setSelectedContent("normal")}
                  style={"nav-item"}
                  buttonStyle={
                    selectedContent == "normal" ? "nav-link active" : "nav-link"
                  }
                  buttonTitle={t("cars")}
                ></ListItem>
              </>
            )}
          </ul>
        </span>
      )}
      <span class="sort d-none d-sm-flex align-items-center">
        <img src="assets/images/SortBy.png" className="mx-1" alt="img" />
        {/* <SelectOptions
          name={"sort"}
          values={data}
          selectedOption={t('Relevance')}
          onChange={selectFilter}
          // style={'text-center'}
          boxStyle={{
            paddingInline: "10px",
            border: "none",
            appearance: 'none',
            width: sortByWidth
          }}
        /> */}
        <ReactSelectCustom
          values={data}
          width={"180px"}
          // boxWidth={'140px'}
          // placeholder={t("Relevance")}
          selectedOption={t("Relevance")}
          onChange={(e) =>
            setSortVal({
              sort: e.item.sort,
              order: e.item.order,
            })
          }
        />
        {/* <div class="dropdown">
          <button class="btn btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown button
          </button>
          <ul class="dropdown-menu">
            {data.map((item) => {
              return (
                <li><a class="dropdown-item" href="#">{item.name}</a></li>
              )
            })}
          </ul>
        </div> */}
      </span>
    </h6>
  );
};
export default SortBy;
