import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Tools/Button";
import { useSelector } from "react-redux";
import Carview from "../CarView";
import { apiManager } from "../../../api/connectAPI";
import { toast } from "react-toastify";
import {
  beginWithImage,
  camelToNormal,
  getNameFromVal,
  isImageOrVideo,
} from "../../../utils/constants";
import { cities } from "../../../data/content";
import { addOrUpdateAd } from "../../../Reducer/api";
import Media from "../Media";
import { useState } from "react";
import moment from "moment";

export default function Advert({
  data,
  setPage,
  onClick,
  back,
  edit,
  next,
  page,
}) {
  const { t, i18n } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  const lang =
    i18n.language === "English"
      ? "en"
      : i18n.language === "Arabic"
      ? "ar"
      : "ku";

  const upload_list = useSelector((state) => state.withoutVin.withoutVin);

  const handlePostAd = async () => {
    // if (!uploadedFiles?.length) {
    //   setInputFileError(t("File is required"));
    //   return;
    // }
    // setSubmit(true);

    // const res = await addOrUpdateAd(data, page, "POST");
    onClick(2, next, data);
  };

  console.log(data?.uploads);

  const getFirstImage = () => {
    let img = null
    if(data?.uploads) {
      img =  beginWithImage?.(
        data?.uploads?.map?.((v) => ({ s3URL: v }))
      )?.[0]
    } else {
      img = "../assets/images/notFound.png"
    }
    return img
  }
  return (
    <>
      <h6 class="back">
        <Button
          onClick={() => {
            setPage(back);
          }}
          title={
            <>
              <img src="assets/images/backbt.png" /> {t("Go back")}
            </>
          }
        />
      </h6>
      <h3 class="Advert mb-3 mb-sm-0">{t("Your Advert")}</h3>
      <div class="set">
        <div class="row">
          {/* <Carview data={data} language={language} /> */}
          <div className="col-lg-6 d-flex justify-content-around">
            <img
              class="viewimg border rounded-1 "
              src={getFirstImage()}
              style={{ objectFit: "contain" }}
              alt="bg"
            />
          </div>
          <div class="col mt-3 mt-sm-0" style={{ fontSize: "16px" }}>
            {/* <h3 className="mt-5">{data?.name?.[lang]}</h3> */}
            <h3 className="">
              {t(data?.properties?.basicDetails?.make?.[lang])}{" "}
              {t(data?.properties?.basicDetails?.model?.[lang])}
            </h3>

            <span className="row my-2">
              <span className="col-7">{t("Fuel Type")} </span>
              <span className="col-5 fw-semibold">
                {t(data?.properties?.specifications?.fuelType?.[lang])
                  ? t(data?.properties?.specifications?.fuelType?.[lang])
                  : "--"}
              </span>
            </span>

            <span className="row my-2">
              <span className="col-7">{t("Engine Size")} </span>
              <span className="col-5 fw-semibold">
                {t(data?.properties?.specifications?.engineSize)
                  ? t(
                      data?.properties?.specifications?.engineSize
                        ? getNameFromVal(
                            "size",
                            data?.properties?.specifications?.engineSize,
                            lang
                          )
                        : "--"
                    )
                  : "--"}
              </span>
            </span>

            <span className="row my-2">
              <span className="col-7">{t("Transmission")} </span>
              <span className="col-5 fw-semibold">
                {data?.properties?.specifications?.gearbox?.[lang]
                  ? t(data?.properties?.specifications?.gearbox?.[lang])
                  : "--"}
              </span>
            </span>

            <span className="row my-2">
              <span className="col-7">{t("Colour")} </span>
              <span className="col-5 fw-semibold">
                {data?.properties?.basicDetails?.color
                  ? t(data?.properties?.basicDetails?.color)
                  : "--"}
              </span>
            </span>
          </div>
          <div class="col-lg-6 col-xxl-7 text-center"></div>
        </div>
      </div>
      <div class="text-center">
        <Button
          style="save_continue"
          onClick={() => {
            handlePostAd();
          }}
          type="submit"
          title={t("Save & Continue")}
        />
      </div>
    </>
  );
}
