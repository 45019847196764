import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Tools/Button";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { clearStatus, fromChacie } from "../../../Reducer/chaiceSlice";
import FormInput from "../../Tools/FormInput";
import { toast } from "react-toastify";
import { errorSms } from "../../../data/content";
import SellFindModel from "../../Tools/SellFindModel";
import { dataInFormat } from "../../Tools/Forms/FirstView/dataFormattingUtil";
import { placeAdvertScreen } from "../../../utils/paths";

export default function PlaceSection({ page }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const submitting = useSelector((state) => state.chacie.submitting);
  const chasis = useSelector((state) => state.chacie.chacie);
  const error = useSelector((state) => state.chacie.error);
  const success = useSelector((state) => state.chacie.success);
  const udata = useSelector((state) => state.auth);
  const navPage = page == "normal" ? "/SellYourCar" : "/RentYourCar";
  const schema = Yup.object().shape({
    chasis: Yup.string()
      .required(t("Chassis number is required"))
      .min(17, t("Chassis number must be exactly 17 characters"))
      .max(17, t("Chassis number must be exactly 17 characters")),
  });
  const profileData = useSelector((state) => state.myprofile.profilearr);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onError = (errors) => {
    console.log(errors);
  };

  const onSubmit = async (data) => {
    if (!udata.token) {
      navigate("/Login", { state: { backPage: `/${navPage}` } });
      return;
    }

    if (submitting == false) {
      // let formData = new FormData();
      // formData.append("chasis", data.vin);
      // formData.append("type", "sell");
      // dispatch(fromChacie(formData));
      dispatch(fromChacie({ chasis: data.chasis, type: "sellRent" }));
    }
  };
  if (success == true) {
    let updatedResult = {};
    const result1 = chasis?.carApiarResponse;
    const result2 = chasis?.vinDecoderResponse;
    if (result1 || result2) {
      updatedResult = dataInFormat(result1, result2).then((res) => {
        navigate(navPage, {
          state: {
            backpage: "home",
            startpage: "SecondChildVIn",
            data: res,
          },
        });
        dispatch(clearStatus());
      });
    }
  }
  if (error && error.status) {
    switch (error.status) {
      case 403:
        toast.error(error.data.message);
        navigate(navPage, {
          state: {
            backpage: "home",
            startpage: "FirstFormWithoutVin",
            data: {},
          },
        });
        break;
      default:
        toast.error(t(errorSms.first));
        break;
    }
  }
  return (
    <section class="place_an">
      <div class="container">
        <div class="main_set" style={{ height: "fit-content" }}>
          <div class="row">
            <div class="col-md-6">
              <div class="set" style={{ padding: "30px" }}>
                <h3>{t("Place an advert on Ako Sayara")}</h3>
                <h5>{t("Get price you deserve")}</h5>
                <form
                  onSubmit={handleSubmit(onSubmit, onError)}
                  className="d-flex flex-column"
                >
                  <div class="form-group">
                    <FormInput
                      disabled={!profileData?.phoneNumberVerified}
                      style={"form-control"}
                      intype={"text"}
                      event={register}
                      name={"chasis"}
                      placeholder={t("Enter your VIN number")}
                    />
                    {errors.chasis && <p>{errors.chasis.message}</p>}
                    <span
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalFindVin"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src="/assets/images/icons_info.png"
                        alt="akar-icons_info"
                      />
                    </span>
                  </div>
                  <Button
                    type={"submit"}
                    disabled={!profileData?.phoneNumberVerified}
                    title={
                      <>
                        {submitting
                          ? t("Please wait...")
                          : t("Create your advert")}{" "}
                        <i class="fa-solid fa-arrow-right"></i>
                      </>
                    }
                  ></Button>
                  {!profileData?.phoneNumberVerified ? (
                    <>
                      <h6>{t("verify phone to post ad")}</h6>
                      <NavLink to={"/MyAccount/MyProfile"}>
                        {t("Verify now")}
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <h6>{t("Or")}</h6>
                      <NavLink to={"/MyAccount"}>
                        {t("Manage existing advert")}
                      </NavLink>
                    </>
                  )}
                </form>
              </div>
            </div>
            <div class="col-md-6" style={{ height: "unset" }}>
              <img
                class="w-100"
                src={
                  placeAdvertScreen
                    ? placeAdvertScreen
                    : "/assets/images/placeimg.webp"
                }
                alt="img"
                style={{
                  objectFit: "cover",
                  borderRadius: "0px 10px 10px 0px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <SellFindModel />
    </section>
  );
}
