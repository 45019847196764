import React from "react";
import NumberInput from "../../NumberInput";
import { useTranslation } from "react-i18next";

export default function RentPrice({ register, errors, data, back }) {
  const { t, i18n } = useTranslation();
  return (
    <div class="other_div other_div2 sp2 row">
      <h3>{t("What’s Your Asking Price?")}*</h3>
      <div class={`form-group ${back == false ? "rentPriceMargin" : ""}`}>
        <div class="form-control">
          <label  style={{marginTop: '0px'}}>{t("Daily")}*</label>
          <NumberInput
            left={true}
            value={data?.askPrice}
            event={register}
            name={"daily_price"}
            placeholder={t("Price ($)")}
          />
          {errors.daily_price && <p>{errors.daily_price?.message}</p>}
        </div>
      </div>
      <div class={`form-group decrement ${back == false ? "rentPriceMargin" : ""}`}>
        <div class="form-control">
          <label  style={{marginTop: '0px'}}>{t("Weekly")}*</label>
          <NumberInput
            left={true}
            value={data?.askPrice1}
            event={register}
            name={"weekly_price"}
            placeholder={t("Price ($)")}
          />
          {errors.weekly_price && <p>{errors.weekly_price?.message}</p>}
        </div>
      </div>
      <div class={`form-group decrement ${back == false ? "rentPriceMargin" : ""}`}>
        <div class="form-control">
          <label  style={{marginTop: '0px'}}>{t("Monthly")}*</label>
          <NumberInput
            left={true}
            value={data?.askPrice2}
            event={register}
            name={"monthly_price"}
            placeholder={t("Price ($)")}
          />
          {errors.monthly_price && <p>{errors.monthly_price?.message}</p>}
        </div>
      </div>
    </div>
  );
}
