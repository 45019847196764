import React from "react";
import Plan from "../../Tools/plan";
import { useLocation, useNavigate } from "react-router-dom";
import { uploadWithoutVinForm } from "../../../Reducer/saveCarWithouVinSlice";
import { useDispatch } from "react-redux";

function RenewPlan() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const data = location.state
  console.log(location.state)
  const handleCallback = () => {
    if(data?.isUpdate) {
      dispatch(uploadWithoutVinForm(data?.updateApiData))
    }
    navigate("/MyAccount/Vehicles");
  }
  return (
    <section class="sell_your">
      <div class="container">
        <div class="incell position-relative">
          <Plan renew={data?.isRenew} page={data.page} details={data.item} renewCallback={handleCallback} isUpdate={data?.isUpdate} />
        </div>
      </div>
    </section>
  );
}

export default RenewPlan;
